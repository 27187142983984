import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {UilEdit} from '@iconscout/react-unicons';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export default function AccountPhoneEditor({
  title, name, value, handlePhone, updateAccountData,
}) {
  const [edited, setEdited] = useState(false);
  const toggleEdited = () => {
    if (edited) {
      updateAccountData();
    }
    setEdited(!edited);
  };

  const handlePhoneNumber = (newPhone) => {
    handlePhone(newPhone);
  };

  return (
    <Row>
      <Col xs={3}>{title}: </Col>
      <Col xs={7}>
        {
          edited ?
            (
              <PhoneInput
                international
                defaultCountry="US"
                value={value}
                onChange={handlePhoneNumber}
              />
            ) :
            <span id={name}>{value}</span>
        }
      </Col>
      <Col xs={2}>
        <UilEdit
          className={edited ? 'blueberry-icon' : ''}
          onClick={toggleEdited}
        />
      </Col>
    </Row>
  );
}

AccountPhoneEditor.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handlePhone: PropTypes.func.isRequired,
  updateAccountData: PropTypes.func.isRequired,
};
