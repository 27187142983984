import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import App from './App';
import ContainerPadding from './components/ContainerPadding';
import ContainerWithoutPadding from './components/ContainerWithoutPadding';
import Home from './routes/Home';
import Login from './routes/Login';
import Signup from './routes/Signup';
import ForgetPassword from './routes/ForgetPassword';
import UpdatePassword from './routes/UpdatePassword';
import Account from './routes/Account';
import OralPhotos from './routes/OralPhotos';
import OralPhotosEditor from './routes/OralPhotosEditor';
import OralPhotosAdd from './routes/OralPhotosAdd';
import Appointments from './routes/Appointments';
import AppointmentAdd from './routes/AppointmentAdd';
import AppointmentEditor from './routes/AppointmentEditor';
import MedicalRecords from './routes/MedicalRecords';
import MedicalRecordsAdd from './routes/MedicalRecordsAdd';
import MedicalRecordsEdit from './routes/MedicalRecordsEdit';
import DentalKnowledgement from './routes/DentalKnowledgement';
import DentalKnowledgementArticle from './routes/DentalKnowledgementArticle';
import OralSuppliers from './routes/OralSuppliers';
import OralSupplierContent from './routes/OralSupplierContent';
import DentalAi from './routes/DentalAi';
import VirtualConsultation from './routes/VirtualConsultation';
import ReservationHistory from './routes/ReservationHistory';
import TestComponent from './routes/TestComponent';
import RequireAuth from './RequireAuth';

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path='/' element={<App />}>
            <Route index element={<Home />} />
            <Route path='account' element={<Account />} />
            <Route path='oral-photo' element={<ContainerPadding />}>
              <Route index element={<OralPhotos />} />
              <Route path='add' element={<OralPhotosAdd />} />
              <Route
                name='oral-edit'
                path=':oralPhotoId/edit'
                element={<OralPhotosEditor />}
              />
            </Route>
            <Route path='appointments' element={<ContainerPadding />}>
              <Route index element={<Appointments />} />
              <Route path='add' element={<AppointmentAdd />} />
              <Route
                name='appointment-edit'
                path=':appointmentId/edit'
                element={<AppointmentEditor />}
              />
            </Route>
            <Route path='medical-records' element={<ContainerPadding />}>
              <Route index element={<MedicalRecords />} />
              <Route path='add' element={<MedicalRecordsAdd />} />
              <Route
                path=':medicalRecordId'
                element={<MedicalRecordsEdit />}
              />
            </Route>
            <Route
              path='dental-knowledge'
              element={<ContainerWithoutPadding />}
            >
              <Route index element={<DentalKnowledgement />} />
              <Route
                path=':knowledgeId'
                element={<DentalKnowledgementArticle />}
              />
            </Route>
            <Route
              path='oral-supplier'
              element={<ContainerWithoutPadding />}
            >
              <Route index element={<OralSuppliers />} />
              <Route path=':supplierId' element={<OralSupplierContent />} />
            </Route>
            <Route
              path='virtual-consultation'
              element={<ContainerWithoutPadding />}
            >
              <Route index element={<VirtualConsultation />} />
              <Route
                path='reservation-record'
                element={<ReservationHistory />}
              />
            </Route>
            <Route path='dental-ai' element={<ContainerWithoutPadding />}>
              <Route index element={<DentalAi />} />
              <Route path='result' element={<TestComponent />} />
            </Route>
          </Route>
        </Route>
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='forget-password' element={<ForgetPassword />} />
        <Route path='update-password' element={<UpdatePassword />} />
      </Routes>
    </BrowserRouter>
  );
};
