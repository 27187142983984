import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
  Row, Col, Form, Button, ToggleButton,
} from 'react-bootstrap';
import {UilBell} from '@iconscout/react-unicons';
import dayjs from 'dayjs';

import AccountPhoneEditor from '../components/AccountPhoneEditor';
import ConfirmModal from '../components/ConfirmModal';

import {useAuth} from '../hooks/AuthContext';
import * as AppointmentApi from '../api/AppointmentApi';

export default function AppointmentEditor() {
  const auth = useAuth();
  const {appointmentId} = useParams();
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState(auth.user);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [oneHourChecked, setOneHourChecked] = useState(false);
  const [oneDayChecked, setOneDayChecked] = useState(false);
  const [oneWeekChecked, setOneWeekChecked] = useState(false);
  const [appointment, setAppointment] = useState({
    appointment_date: dayjs().format('YYYY-MM-DD'),
    appointment_time: dayjs().format('HH:mm'),
    note: '',
    email_alert: false,
    sms_alert: false,
  });

  const handleToggleCheckbox = (e) => {
    const {name} = e.target;
    setAppointment((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setAppointment((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhone = (phoneNumber) => {
    setAccountData((prevState) => ({
      ...prevState,
      phone: phoneNumber,
    }));
  };

  const uploadAccountData = () => {
    auth.updateAccountData(accountData);
  };

  const onClickUpdateAppointment = () => {
    const appointmentData = {...appointment};
    appointmentData.appointment_date =
      dayjs(
        `${appointment.appointment_date} ${appointment.appointment_time}`,
      ).format();
    appointmentData.alert_before = [];
    if (oneHourChecked) {
      appointmentData.alert_before.push(
        dayjs(appointmentData.appointment_date).subtract(1, 'hour').unix());
    }
    if (oneDayChecked) {
      appointmentData.alert_before.push(dayjs(
        appointmentData.appointment_date).subtract(1, 'day').unix());
    }
    if (oneWeekChecked) {
      appointmentData.alert_before.push(dayjs(
        appointmentData.appointment_date).subtract(7, 'day').unix());
    }
    AppointmentApi.updateAppointment(appointmentId, appointmentData)
      .then(() => {
        navigate('/appointments/');
      });
  };

  useEffect(() => {
    AppointmentApi.getAppointmentById(appointmentId).then((appointmentData) => {
      const appointmentDate = dayjs(appointmentData.appointment_date);
      appointmentData.appointment_date = appointmentDate.format('YYYY-MM-DD');
      appointmentData.appointment_time = appointmentDate.format('HH:mm');
      const alertBefore = [...appointmentData.alert_before];
      delete appointmentDate.alert_before;
      for (const time of alertBefore) {
        const hourDiff = appointmentDate.diff(time.alert_time, 'hour');
        switch (hourDiff) {
        case 1:
          setOneHourChecked((oldChecked) => !oldChecked);
          break;
        case 24:
          setOneDayChecked((oldChecked) => !oldChecked);
          break;
        case 168:
          setOneWeekChecked((oldChecked) => !oldChecked);
          break;
        default:
        }
      }
      setAppointment(appointmentData);
    });
  }, [appointmentId]);

  return (
    <main>
      <h2 className='page-title'>Edit Appointment</h2>
      <div style={{marginTop: '1rem'}}>
        <Form>
          <Form.Group as={Row} className='mb-3' controlId='formDate'>
            <Form.Label column xs={2}>
              Date
            </Form.Label>
            <Col xs={10}>
              <Form.Control
                type='date'
                name='appointment_date'
                value={appointment.appointment_date}
                onChange={handleChangeForm}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3' controlId='formTime'>
            <Form.Label column xs={2}>
              Time
            </Form.Label>
            <Col xs={10}>
              <Form.Control
                type='time'
                name='appointment_time'
                value={appointment.appointment_time}
                onChange={handleChangeForm}
              />
            </Col>
          </Form.Group>
          <Form.Control
            as='textarea'
            rows={5}
            placeholder='add some note...'
            name='note'
            value={appointment.note}
            onChange={handleChangeForm}
          />
          <Form.Group as={Row}
            className='mb-3'
            controlId='formPlaintextPassword'
          >
            <Form.Label column xs={4} style={{textAlign: 'right'}}>
              <UilBell className='unicon' />
              Alert
            </Form.Label>
            <Col
              xs={8}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ToggleButton
                id='alert-email-check'
                type='checkbox'
                variant='outline-primary'
                value='email'
                name='email_alert'
                checked={appointment.email_alert}
                onChange={handleToggleCheckbox}
                className='appointment-checkbox-btn'
              >
                email
              </ToggleButton>
              <ToggleButton
                id='alert-sms-check'
                type='checkbox'
                variant='outline-primary'
                value='sms'
                name='sms_alert'
                checked={appointment.sms_alert}
                onChange={handleToggleCheckbox}
                className='appointment-checkbox-btn'
              >
                SMS
              </ToggleButton>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className='mb-3'
            controlId='formPlaintextPassword'
          >
            <Form.Label column xs={4} style={{textAlign: 'right'}}>
              Alert Before
            </Form.Label>
            <Col
              xs={8}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ToggleButton
                id='before-hour-check'
                type='checkbox'
                variant='outline-primary'
                value='1hour'
                checked={oneHourChecked}
                onChange={() => setOneHourChecked(!oneHourChecked)}
                className='appointment-checkbox-btn'
              >
                1 hour
                {oneHourChecked}
              </ToggleButton>
              <ToggleButton
                id='before-day-check'
                type='checkbox'
                variant='outline-primary'
                value='1day'
                checked={oneDayChecked}
                onChange={() => setOneDayChecked(!oneDayChecked)}
                className='appointment-checkbox-btn'
              >
                1 day
                {oneDayChecked}
              </ToggleButton>
              <ToggleButton
                id='before-week-check'
                type='checkbox'
                variant='outline-primary'
                value='1week'
                checked={oneWeekChecked}
                onChange={() => setOneWeekChecked(!oneWeekChecked)}
                className='appointment-checkbox-btn'
              >
                1 week
                {oneWeekChecked}
              </ToggleButton>
            </Col>
          </Form.Group>
        </Form>
      </div>
      <div style={{padding: '0 1rem', marginTop: '1rem'}}>
        <Button
          className='melrose-btn'
          onClick={() => setConfirmModalShow(true)}
        >
          Update Appointment
        </Button>
      </div>
      <ConfirmModal
        modalTitle='Remind'
        show={confirmModalShow}
        btnText='Confirm'
        onHide={() => setConfirmModalShow(false)}
        clickBtn={() => onClickUpdateAppointment()}
      >
        <div style={{fontSize: '1.2rem'}}>
          <p>
            Your appointment reminder notification will be sent to the following
          </p>
          {
            appointment.email_alert &&
            (
              <Row>
                <Col xs={3}>E-mail:</Col>
                <Col xs={7}>
                  {accountData.email ? accountData.email : ''}
                </Col>
              </Row>
            )
          }
          {
            appointment.sms_alert &&
            (
              <div>
                <AccountPhoneEditor
                  title='Phone'
                  name='phone'
                  value={accountData.phone || ''}
                  handlePhone={handlePhone}
                  updateAccountData={uploadAccountData}
                />
              </div>
            )
          }
        </div>
      </ConfirmModal>
    </main>
  );
}
