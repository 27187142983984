import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';

export default function DisplayModal({
  children, title, show, onHide, ...props
}) {
  return (
    <Modal
      {...props}
      onHide={onHide}
      show={show}
      size='lg'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 style={{fontWeight: '700'}}>
            {title}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer style={{display: 'block'}}>
        <div>
          <Button
            variant='primary'
            className='melrose-btn'
            onClick={onHide}
          >
            Back
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

DisplayModal.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};
