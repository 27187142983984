import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Row, Col} from 'react-bootstrap';
import {UilEdit} from '@iconscout/react-unicons';

export default function AccountDataEditor({
  title, name, value, handleChangeForm, updateAccountData,
}) {
  const [edited, setEdited] = useState(false);

  const toggleEdited = () => {
    if (edited) {
      updateAccountData();
    }
    setEdited(!edited);
  };

  return (
    <Row>
      <Col xs={3}>{title}</Col>
      <Col xs={7}>
        {
          edited ?
            (
              <Form>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Control
                    type='text'
                    placeholder={name}
                    name={name}
                    value={value}
                    onChange={handleChangeForm}
                  />
                </Form.Group>
              </Form>
            ) :
            <span id={name}>{value}</span>
        }
      </Col>
      <Col xs={2}>
        <UilEdit
          className={edited ? 'blueberry-icon' : ''}
          onClick={toggleEdited}
        />
      </Col>
    </Row>
  );
}

AccountDataEditor.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  updateAccountData: PropTypes.func.isRequired,
};
