import * as React from 'react';
import {Image} from 'react-bootstrap';

import logo from '../assets/image/DB-logo.png';

export default function Logo() {
  return (
    <div className='login-logo'>
      <Image src={logo} className='logo' />
    </div>
  );
}
