import * as React from 'react';
import {Image} from 'react-bootstrap';

import hippa from '../assets/image/HIPPA.png';

export default function Logo() {
  return (
    <div className='login-hippa'>
      <Image src={hippa} className='hippa' />
    </div>
  );
}
