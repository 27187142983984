import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Image, Row, Col} from 'react-bootstrap';

export default function OralSupplierList(props) {
  return (
    <Link to={`./${props.id}`}>
      <li key={props.id} className='content-list-element'>
        <Row style={{height: '100%'}}>
          <Col xs={3} style={{maxHeight: '100%', maxWidth: '100%'}}>
            <Image src={props.image} />
          </Col>
          <Col
            xs={9}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <h4 style={{color: '#474747', margin: '0'}}>{props.title}</h4>
          </Col>
        </Row>
      </li>
    </Link>
  );
}

OralSupplierList.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};
