import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
  ToggleButton, Container, Spinner,
} from 'react-bootstrap';
import ReservationCard from '../components/ReservationCard';
import ReservationDetailModal from '../components/ReservationDetailModal';

import * as ReservationApi from '../api/ReservationApi';
import * as ComplaintApi from '../api/ComplaintApi';

export default function ReservationHistory() {
  const radios = [
    {id: 0, name: 'Current Reservation', value: 'future'},
    {id: 1, name: 'Consultation record', value: 'history'},
  ];
  const [sequence, setSepuence] = useState('future');
  const [reservationArray, setReservationArray] = useState([]);
  const [retrieveReservations, setRetrieveReservations] = useState(false);
  const [reservation, setReservation] = useState();
  const [complaint, setComplaint] = useState();
  const [answers, setAnswers] = useState([]);
  const [reservationModalShow, setReservationModalShow] = useState(false);

  const showReservationDetail = (reservationId) => {
    ReservationApi.getReservation(reservationId).then((reservationData) => {
      setReservation(reservationData);
      return axios.all(
        reservation.answer.map(
          (answer) => ReservationApi.getAnswer(answer.id)));
    }).then((answersData) => {
      answersData.sort(
        (a, b) => a.form_and_questions.order - b.form_and_questions.order,
      );
      setAnswers(answersData);
      return ComplaintApi.getComplaint(reservationId);
    }).then((complaintData) => {
      setComplaint(complaintData);
      setReservationModalShow(true);
    });
  };

  const cancelReservation = (reservationId) => {
    ReservationApi.deleteReservation(reservationId).then(() => {
      setReservationArray(
        reservationArray.filter(
          (reservationData) => reservationData.id !== reservationId,
        ),
      );
      setReservationModalShow(false);
    });
  };

  useEffect(() => {
    setRetrieveReservations(false);
    ReservationApi.getReservationList(sequence).then((reservations) => {
      setRetrieveReservations(true);
      setReservationArray(reservations);
    });
  }, [sequence]);

  return (
    <main>
      <h2 className='page-title'>Reservation History</h2>
      <Container>
        <div style={{padding: '1rem 0', textAlign: 'center'}}>
          {
            radios.map((radio) => (
              <ToggleButton
                key={radio.id}
                id={`radio-${radio.id}`}
                type='radio'
                variant='outline-primary'
                name='radio'
                value={radio.value}
                checked={sequence === radio.value}
                onChange={(e) => setSepuence(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))
          }
        </div>
        {
          retrieveReservations ?
            (
              <ul className='list-style-none'>
                {
                  reservationArray.map((reservation) => (
                    <ReservationCard
                      key={reservation.id}
                      id={reservation.id}
                      timezone={reservation.timezone}
                      reservationTime={reservation.reservation_time}
                      doctor={reservation.doctor_aggregation.doctor.name}
                      showReservationDetail={showReservationDetail}
                    />
                  ))
                }
              </ul>
            ) :
            (
              <div style={{textAlign: 'center'}}>
                <Spinner animation='border' variant='dark' />
              </div>
            )
        }
      </Container>
      <ReservationDetailModal
        show={reservationModalShow}
        onHide={() => setReservationModalShow(false)}
        reservation={reservation}
        answers={answers}
        complaint={complaint}
        cancelReservation={cancelReservation}
      />
    </main>
  );
}
