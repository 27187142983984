import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import * as d3 from 'd3';

export default function DentalAiResult({
  dentalAi, dentalAiResult, retryDentalAi,
}) {
  const dentalAiText = {
    caries: 'Caries',
    wisdom_teeth: 'Wisdom Teeth',
  };

  const [height, setHeight] = useState(null);

  useEffect(() => {
    const image = d3.select('#dental-ai-image');
    image.attr('xlink:href', dentalAiResult.image_url);

    d3.image(dentalAiResult.image_url).then((res) => {
      const {
        width: imageWidth, height: imageHeight,
      } = image.node().getBBox();
      setHeight(imageHeight);
      const xscale = d3.scaleLinear()
        .domain([0, res.naturalWidth])
        .range([0, imageWidth]);
      const yscale = d3.scaleLinear()
        .domain([0, res.naturalHeight])
        .range([0, imageHeight]);

      const strokeColor = ['red', 'green', 'yellow', 'purple', 'blue'];
      const strokeColorKey = {};

      dentalAiResult.detection_selection.forEach((rect) => {
        const width = xscale(rect.xmax) - xscale(rect.xmin);
        const height = yscale(rect.ymax) - yscale(rect.ymin);

        if (!Object.prototype.hasOwnProperty.call(
          strokeColorKey, rect.crop_prediction,
        )) {
          strokeColorKey[rect.crop_prediction] = strokeColor.shift();
        }

        d3.select('#dental-ai-svg')
          .append('rect')
          .attr('width', width)
          .attr('height', height)
          .attr('x', xscale(rect.xmin))
          .attr('y', yscale(rect.ymin))
          .style('stroke', strokeColorKey[rect.crop_prediction])
          .style('stroke-width', 3)
          .style('fill-opacity', 0);
      });
    });
  }, [dentalAiResult]);

  return (
    <main>
      <h2 className='page-title'>{dentalAiText[dentalAi]} Result</h2>
      <div style={{backgroundColor: '#FFF', padding: '0.5rem'}}>
        <svg id='dental-ai-svg' style={{width: '100%', height}}>
          <image id='dental-ai-image' style={{width: '100%'}} />
        </svg>
        <center>
          <p style={{margin: '0.5rem 0'}}>
            Medical consultation can allow you to further understand the
            condition and cause of the disease. This content is only for
            consultation reference. Because the cause of the disease is
            related to personal physique, gender, age, family history
            and environment, thus it is impossible to provide medical
            treatment and replace the doctor&apos;s face-to-face diagnosis.
            If you have a disease, you should still seek medical
            treatment immediately.
          </p>
        </center>
        <Button className='melrose-btn' onClick={retryDentalAi}>
          Try Another Detection
        </Button>
      </div>
    </main>
  );
}

DentalAiResult.propTypes = {
  dentalAi: PropTypes.string,
  dentalAiResult: PropTypes.object,
  retryDentalAi: PropTypes.func,
};
