import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import * as DentalKnowledgeApi from '../api/DentalKnowledgeApi';

export default function DentalKnowledgementArticle() {
  const {knowledgeId} = useParams();
  const [dentalKnowledge, setDentalKnowledge] = useState({});
  const [dentalKnowledgeEssay, setDentalKnowledgeEssay] = useState('');

  useEffect(() => {
    DentalKnowledgeApi.getDentalKnowledge(knowledgeId)
      .then((res) => setDentalKnowledge(res));
  }, [knowledgeId]);

  useEffect(() => {
    DentalKnowledgeApi
      .getDentalKnowledgeMarkdown(dentalKnowledge.content_markdown)
      .then((res) => setDentalKnowledgeEssay(res));
  }, [dentalKnowledge]);

  return (
    <section>
      <h2 className='page-title'>{dentalKnowledge.title}</h2>
      <p style={{color: '#6096FD', padding: '0 1rem'}}>
        {dentalKnowledge.updateDate}
      </p>
      <div className='padding-content'>
        <ReactMarkdown>
          {dentalKnowledgeEssay}
        </ReactMarkdown>
      </div>
    </section>
  );
}
