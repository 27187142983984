import React, {useState, useEffect} from 'react';

import dentalKnowledgeImage from '../assets/image/icon-knowledgement.png';

import TopicTitle from '../components/TopicTitle';
import DentalKnowledgeList from '../components/DentalKnowledgeList';

import * as DentalKnowledgeApi from '../api/DentalKnowledgeApi';

export default function DentalKnowledgement() {
  const [dentalKnowledges, setDentalKnowledges] = useState([]);

  useEffect(() => {
    DentalKnowledgeApi.getAllDentalKnowledge()
      .then((res) => setDentalKnowledges(res));
  }, []);

  return (
    <main>
      <TopicTitle
        topicName='Dental & Tx Knowledgement'
        topicImage={dentalKnowledgeImage}
      />
      <ul className='list-style-none'>
        {
          dentalKnowledges.map((dentalKnowledge) => (
            <DentalKnowledgeList
              id={dentalKnowledge.id}
              key={dentalKnowledge.id}
              title={dentalKnowledge.title}
              date={dentalKnowledge.update_date}
              image={dentalKnowledge.cover_image}
            />
          ))
        }
      </ul>
    </main>
  );
}
