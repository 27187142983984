import React, {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import Logo from '../components/Logo';
import {useAuth} from '../hooks/AuthContext';

export default function UpdatePassword() {
  const auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setPasswordData((prevRegisterData) => ({
      ...prevRegisterData,
      [name]: value,
    }));
  };

  const updatePasswordWithKey = (data) => {
    auth.updatePasswordWithKey(data).then(() => {
      navigate('/login', {replace: true});
    });
  };

  const verifyPassword = (password, confirmPassword) =>
    password === confirmPassword;

  const clickSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    const data = {
      password: passwordData.newPassword,
      key: searchParams.get('key'),
    };

    if (verifyPassword(
      passwordData.newPassword, passwordData.confirmNewPassword)) {
      updatePasswordWithKey(data);
    } else {
      console.log('Password and Confirm Password should be the same.');
    }
  };

  return (
    <main>
      <center>
        <Logo />
        <div className='central-container'>
          <Form validated={validated} onSubmit={clickSubmit}>
            <Form.Group className='mb-3' controlId='formNewPassword'>
              <Form.Control
                name='newPassword'
                type='password'
                placeholder='New password'
                onChange={handleChangeForm}
                autoComplete='on'
                required
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formConfirmNewPassword'>
              <Form.Control
                name='confirmNewPassword'
                type='password'
                placeholder='Confirm New Password'
                onChange={handleChangeForm}
                autoComplete='on'
                required
              />
            </Form.Group>
            <Button
              className='melrose-btn'
              style={{marginTop: '2rem'}}
              type='submit'
            >
              Update password
            </Button>
          </Form>
        </div>
      </center>
    </main>
  );
}
