import React, {useState} from 'react';
import {
  Container, Form, Row, Col, Button, Image,
} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import ChooseFile from '../components/ChooseFile';
import ConfirmModal from '../components/ConfirmModal';
import DentalBookPrivacyPolicy from '../components/DentalBookPrivacyPolicy';
import HippaNoticeOfPrivacy from '../components/HippaNoticeOfPrivacy';

import * as OralPhotoApi from '../api/OralPhotoApi';

export default function OralPhotosAdd() {
  const navigate = useNavigate();
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [newOralPhoto, setNewOralPhoto] = useState({
    shoot_date: dayjs().format('YYYY-MM-DD'),
    photo_type: 'X-ray',
    image_link: null,
    note: '',
  });

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setNewOralPhoto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const uploadOralPhoto = (newImage) => {
    setNewOralPhoto((prevState) => ({
      ...prevState,
      image_link: newImage[0].file_link,
    }));
  };

  const addRecord = () => {
    OralPhotoApi.createOralPhoto(newOralPhoto).then(() => {
      navigate('/oral-photo/');
    });
  };

  return (
    <Container>
      <h2 className='page-title'>Add New Photo</h2>
      <Form>
        <Form.Group as={Row} className='mb-3' controlId='formPlaintextEmail'>
          <Form.Label column xs={2}>
            Date
          </Form.Label>
          <Col xs={10}>
            <Form.Control
              type='date'
              value={newOralPhoto.shoot_date}
              name='shoot_date'
              onChange={handleChangeForm}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3' controlId='formPlaintextEmail'>
          <Form.Label column xs={2}>
            Type
          </Form.Label>
          <Col xs={10}>
            <Form.Select
              name='photo_type'
              value={newOralPhoto.photo_type}
              onChange={handleChangeForm}
            >
              <option value='Photo'>Photo</option>
              <option value='X-ray'>X-ray</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {newOralPhoto.image_link ?
          <Image src={newOralPhoto.image_link} /> :
          <ChooseFile
            height='15rem'
            accept='image/*'
            addFile={uploadOralPhoto}
          />
        }
        <Form.Control
          as='textarea'
          rows={7}
          style={{backgroundColor: '#FFF'}}
          placeholder='Add some notes...'
          name='note'
          value={newOralPhoto.note}
          onChange={handleChangeForm}
        />
      </Form>
      <div style={{padding: '0 1rem', marginTop: '1rem'}}>
        <Button
          className='melrose-btn'
          onClick={() => setConfirmModalShow(true)}
        >
          Add Photo
        </Button>
      </div>
      <ConfirmModal
        modalTitle='Disclaimer'
        show={confirmModalShow}
        btnText='Add Photo'
        onHide={() => setConfirmModalShow(false)}
        clickBtn={() => addRecord()}
      >
        <div style={{height: '65vh', overflowX: 'auto'}}>
          <DentalBookPrivacyPolicy />
          <HippaNoticeOfPrivacy />
        </div>
      </ConfirmModal>
    </Container>
  );
}
