import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import {UilTrash} from '@iconscout/react-unicons';
import AccountDataEditor from '../components/AccountDataEditor';
import FacebookbindingIcon from '../components/FacebookbindingIcon';
import GooglebindingIcon from '../components/GooglebindingIcon';
import ApplebindingIcon from '../components/ApplebindingIcon';
import ConfirmModal from '../components/ConfirmModal';
import DisplayModal from '../components/DisplayModal';
import AccountPhoneEditor from '../components/AccountPhoneEditor';
import TermsAndConditions from '../components/TermsAndConditions';
import DentalBookPrivacyPolicy from '../components/DentalBookPrivacyPolicy';
import HippaNoticeOfPrivacy from '../components/HippaNoticeOfPrivacy';

import {useAuth} from '../hooks/AuthContext';
import * as AccountApi from '../api/AccountApi';

export default function Account() {
  const auth = useAuth();
  const [termsConditionsShow, setTermsConditionsShow] = useState(false);
  const [privacyPloicyShow, setPrivacyPloicyShow] = useState(false);
  const [accountData, setAccountData] = useState(auth.user);
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);

  const navigate = useNavigate();

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setAccountData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhone = (phoneNumber) => {
    setAccountData((prevState) => ({
      ...prevState,
      phone: phoneNumber,
    }));
  };

  const uploadAccountData = () => {
    auth.updateAccountData(accountData);
  };

  const deleteAccount = () => {
    AccountApi.deleteAccount().then(() => {
      auth.logout(() => {
        navigate('/login', {replace: true});
      });
    });
  };

  useEffect(() => {
    setAccountData(auth.user);
  }, [auth.user]);

  return (
    <main>
      <h1
        className='page-title'
        style={{marginLeft: '1rem'}}
      >
        Profile Settings
      </h1>
      <section className='account-section'>
        <h4>Profile</h4>
        <ul className='list-style-none'>
          <li>
            <Row>
              <Col xs={3}>Email</Col>
              <Col xs={7}>
                <span id='email'>{accountData.email}</span>
              </Col>
            </Row>
          </li>
          <li>
            <AccountDataEditor
              title='Name'
              name='username'
              value={accountData.username}
              handleChangeForm={handleChangeForm}
              updateAccountData={uploadAccountData}
            />
          </li>
          <li>
            <AccountPhoneEditor
              title='Phone'
              name='phone'
              value={accountData.phone || ''}
              handlePhone={handlePhone}
              updateAccountData={uploadAccountData}
            />
          </li>
        </ul>
      </section>
      <section className='account-section'>
        <h4>Social Account Connect</h4>
        <ul className='list-style-none'>
          <li>
            <Row>
              <Col xs={3}>Google</Col>
              <Col xs={7}>
                <span id='google-account'>
                  {
                    accountData.google_account ?
                      accountData.google_account.email :
                      ''
                  }
                </span>
              </Col>
              <Col xs={2}>
                <GooglebindingIcon socialAccount={accountData.google_account} />
              </Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col xs={3}>Apple</Col>
              <Col xs={7}>
                <span id='apple-account'>
                  {
                    accountData.apple_account ?
                      accountData.apple_account.email :
                      ''
                  }
                </span>
              </Col>
              <Col xs={2}>
                <ApplebindingIcon socialAccount={accountData.apple_account} />
              </Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col xs={3}>Facebook</Col>
              <Col xs={7}>
                <span id='facebook-account'>
                  {
                    accountData.facebook_account ?
                      accountData.facebook_account.email :
                      ''
                  }
                </span>
              </Col>
              <Col xs={2}>
                <FacebookbindingIcon
                  socialAccount={accountData.facebook_account}
                />
              </Col>
            </Row>
          </li>
        </ul>
      </section>
      <section className='account-section'>
        <h4>Service</h4>
        <ul className='list-style-none'>
          <li>
            <Row>
              <Col>
                <p style={{marginBottom: '0'}}>
                  <span
                    style={{fontSize: '14px', color: '#0d6efd'}}
                    onClick={() => setTermsConditionsShow(true)}
                  >
                    Terms of Conditions
                  </span>
                  <span>&nbsp;&&nbsp;</span>
                  <span
                    style={{fontSize: '14px', color: '#0d6efd'}}
                    onClick={() => setPrivacyPloicyShow(true)}
                  >
                    Privacy Policy
                  </span>
                </p>
              </Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col xs={4}>
                Customer Service Email
              </Col>
              <Col xs={8}>
                <a href='mailto:Service@AIDentalBook.com'>
                  Service@AIDentalBook.com
                </a>
              </Col>
            </Row>
          </li>
        </ul>
      </section>
      <section className='account-section'>
        <h4>Delete Data</h4>
        <ul className='list-style-none'>
          <li>
            <Row>
              <Col xs={2}>
                <UilTrash />
              </Col>
              <Col
                xs={10}
                onClick={() => setDeleteConfirmShow(true)}
              >
                Delete Account and Data
              </Col>
            </Row>
          </li>
        </ul>
      </section>
      <ConfirmModal
        modalTitle='Delete Accocunt'
        show={deleteConfirmShow}
        btnText='Delete'
        onHide={() => setDeleteConfirmShow(false)}
        clickBtn={() => deleteAccount()}
      >
        <p style={{fontSize: '1.2rem'}}>
          This process will delete all data including
          all uploaded X-Rays and Photos.
          Please make sure you have made all necessary backup,
          we will not be able to retrieve your data.
        </p>
      </ConfirmModal>
      <DisplayModal
        show={termsConditionsShow}
        onHide={() => setTermsConditionsShow(false)}
        title={
          'Terms and Conditions of Usage of ' +
          'our Website and Mobile Application'
        }
      >
        <div style={{height: '65vh', overflowX: 'auto'}}>
          <TermsAndConditions />
        </div>
      </DisplayModal>
      <DisplayModal
        show={privacyPloicyShow}
        onHide={() => setPrivacyPloicyShow(false)}
        title='Web And Mobile Privacy Policy'
      >
        <div style={{height: '65vh', overflowX: 'auto'}}>
          <DentalBookPrivacyPolicy />
          <HippaNoticeOfPrivacy />
        </div>
      </DisplayModal>
    </main>
  );
}
