import axios from 'axios';
import {getToken} from './AccountApi';

const request = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/reservation/`,
  headers: {
    Accept: 'application/json',
  },
});

request.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

request.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      window.location.href = '/login';
    }
    return err;
  },
);

const answerRequest = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/form/answer/`,
  headers: {
    Accept: 'application/json',
  },
});

answerRequest.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

answerRequest.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      window.location.href = '/login';
    }
    return err;
  },
);

export const getReservationList = (period) =>
  request.get(`?period=${period}`).then((res) => res.data);
export const getReservation = (reservationId) =>
  request.get(`${reservationId}/`).then((res) => res.data);
export const deleteReservation = (reservationId) =>
  request.delete(`${reservationId}/`).then((res) => res.data);
export const getAnswer = (answerId) =>
  answerRequest.get(`${answerId}/`).then((res) => res.data);
