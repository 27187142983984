import React, {useState} from 'react';
import {
  Form, Button,
} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import LogoBanner from '../components/LogoBanner';
import Logo from '../components/Logo';
import DisplayModal from '../components/DisplayModal';
import MessageModal from '../components/MessageModal';
import TermsAndConditions from '../components/TermsAndConditions';
import DentalBookPrivacyPolicy from '../components/DentalBookPrivacyPolicy';
import HippaNoticeOfPrivacy from '../components/HippaNoticeOfPrivacy';


import {useAuth} from '../hooks/AuthContext';

export default function Signup() {
  const auth = useAuth();
  const [termsConditionsShow, setTermsConditionsShow] = useState(false);
  const [errorMessageModalShow, setErrorMessageModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [privacyPloicyShow, setPrivacyPloicyShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [registerData, setRegisterData] = useState({
    email: '',
    phone: '',
    username: '',
    password: null,
    confirmPassword: '',
  });

  const navigate = useNavigate();

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setRegisterData((prevRegisterData) => ({
      ...prevRegisterData,
      [name]: value,
    }));
  };

  const handlePhoneNumber = (phone) => {
    setRegisterData((prevRegisterData) => ({
      ...prevRegisterData,
      phone,
    }));
  };

  const showMessage = (message) => {
    setErrorMessage(message);
    setErrorMessageModalShow(true);
  };

  const verifyPassword = (password, confirmPassword) =>
    password === confirmPassword;

  const registerUser = async (data) => {
    const signUpResponse = await auth.register((data), () => {
      navigate('/login', {replace: true});
    });
    if (signUpResponse) {
      showMessage(signUpResponse.error);
    }
  };

  const clickSignup = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    const signupData = {
      email: registerData.email,
      phone: registerData.phone,
      username: registerData.username,
      password: registerData.password,
    };

    if (verifyPassword(registerData.password, registerData.confirmPassword)) {
      registerUser(signupData);
    } else {
      showMessage('Password and Confirm Password should be the same.');
    }
  };

  return (
    <main>
      <center>
        <LogoBanner />
        <Logo />
        <div className='central-container'>
          <Form validated={validated} onSubmit={clickSignup}>
            <Form.Group className='mb-3' controlId='formEmail'>
              <Form.Control
                name='email'
                type='email'
                placeholder='Email'
                onChange={handleChangeForm}
                autoComplete='on'
                required
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formPhone'>
              <PhoneInput
                international
                defaultCountry="US"
                value={registerData.phone}
                onChange={handlePhoneNumber}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formName'>
              <Form.Control
                name='username'
                type='text'
                placeholder='Name'
                onChange={handleChangeForm}
                autoComplete='on'
                required
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formPassword'>
              <Form.Control
                name='password'
                type='password'
                placeholder='Password'
                onChange={handleChangeForm}
                autoComplete='on'
                required
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formConfirmPassword'>
              <Form.Control
                name='confirmPassword'
                type='password'
                placeholder='Confirm Password'
                onChange={handleChangeForm}
                autoComplete='on'
                required
              />
            </Form.Group>
            <Form.Group style={{fontSize: '14px', textAlign: 'left'}}>
              <Form.Check type='checkbox' id='check-api-checkbox'>
                <Form.Check.Input type='checkbox' required />
                <Form.Check.Label>
                  I have read and agree to the&nbsp;
                  <span
                    role='link'
                    style={{fontSize: '14px', color: '#0d6efd'}}
                    onClick={() => setTermsConditionsShow(true)}
                  >
                    Terms of Service
                  </span>
                  &nbsp;and the&nbsp;
                  <span
                    role='link'
                    style={{fontSize: '14px', color: '#0d6efd'}}
                    onClick={() => setPrivacyPloicyShow(true)}
                  >
                    Privacy Policy.
                  </span>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Button
              className='melrose-btn'
              style={{marginTop: '2rem'}}
              type='submit'
            >
              Sign up
            </Button>
          </Form>
        </div>
        <DisplayModal
          show={termsConditionsShow}
          onHide={() => setTermsConditionsShow(false)}
          title={
            'Terms and Conditions of Usage ' +
            'of our Website and Mobile Application'
          }
        >
          <div style={{height: '65vh', overflowX: 'auto'}}>
            <TermsAndConditions />
          </div>
        </DisplayModal>
        <DisplayModal
          show={privacyPloicyShow}
          onHide={() => setPrivacyPloicyShow(false)}
          title='Web And Mobile Privacy Policy'
        >
          <div style={{height: '65vh', overflowX: 'auto'}}>
            <DentalBookPrivacyPolicy />
            <HippaNoticeOfPrivacy />
          </div>
        </DisplayModal>
        <MessageModal
          show={errorMessageModalShow}
          onHide={() => setErrorMessageModalShow(false)}
          textmessage={errorMessage}
        />
      </center>
    </main>
  );
}
