import React from 'react';
import banner from '../assets/image/DB-logo-banner.png';

export default function LogoBanner() {
  return (
    <div
      style={{
        backgroundColor: '#FAA7B8',
        paddingTop: '30px',
      }}
    >
      <img src={banner} className='App-logo' alt='banner' />
    </div>
  );
}
