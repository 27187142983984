import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export default function ReservationCard({
  id, timezone, reservationTime, doctor, showReservationDetail,
}) {
  const content = {
    margin: '0',
    color: '#dc3545',
    fontWeight: 'bold',
  };

  return (
    <li className='content-card-element' onClick={() => {
      showReservationDetail(id);
    }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          color: '#474747',
        }}
      >
        <p style={content}>{timezone}</p>
        <h4 style={content}>
          {dayjs(reservationTime).format('YYYY-MM-DD HH:mm')}
        </h4>
        <h4 style={content}>{doctor}</h4>
      </div>
    </li>
  );
}

ReservationCard.propTypes = {
  id: PropTypes.string,
  timezone: PropTypes.string,
  reservationTime: PropTypes.string,
  doctor: PropTypes.string,
  showReservationDetail: PropTypes.func,
};
