import React from 'react';
import PropTypes from 'prop-types';
import {Image, Row, Col} from 'react-bootstrap';

export default function TopicTitle({topicName, topicImage}) {
  return (
    <div className='topic-title'>
      <Row>
        <Col xs={8}>
          <h1
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              fontWeight: 600,
            }}
          >
            {topicName}
          </h1>
        </Col>
        <Col xs={4}>
          <Image src={topicImage} />
        </Col>
      </Row>
    </div>
  );
}

TopicTitle.propTypes = {
  topicName: PropTypes.string.isRequired,
  topicImage: PropTypes.string.isRequired,
};
