import React from 'react';
import PropTypes from 'prop-types';
import AppleSignin from 'react-apple-signin-auth';
import {UilLink} from '@iconscout/react-unicons';
import {useAuth} from '../hooks/AuthContext';

export default function ApplebindingIcon({socialAccount}) {
  const auth = useAuth();

  const bindAppleAccount = (appleUser) => {
    const data = {
      login_type: 'apple',
      token: appleUser.authorization.id_token,
    };
    auth.bindSocialMediaAccount(data);
  };

  const deleteAppleAccount = () => {
    auth.deleteSocialMediaAccount(
      'apple',
      socialAccount.social_media_account,
    );
  };

  return (
    <>
      {
        socialAccount ?
          (
            <a
              className='blueberry-icon'
              onClick={deleteAppleAccount}
            >
              <UilLink />
            </a>
          ) :
          (
            <AppleSignin
              authOptions={{
                clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                scope: 'email name',
                redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
                state: '',
                nonce: 'nonce',
                usePopup: true,
              }}
              uiType='dark'
              className='apple-auth-btn'
              buttonExtraChildren='continue with Apple'
              onSuccess={(response) => bindAppleAccount(response)}
              render={(props) => <a {...props}><UilLink /></a>}
            />
          )
      }

    </>
  );
}

ApplebindingIcon.propTypes = {
  socialAccount: PropTypes.object.isRequired,
};
