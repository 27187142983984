import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Spinner} from 'react-bootstrap';

export default function ConfirmModal(props) {
  const {modalTitle, children, btnText, clickBtn, onHide, ...rest} = props;
  const [switchBtn, setSwitchBtn] = useState(false);

  const handleClickBtn = () => {
    toggleSwitchBtn();
    clickBtn();
  };

  const toggleSwitchBtn = () => {
    setSwitchBtn(!switchBtn);
  };

  const turnoffSwitchBtn = () => {
    setSwitchBtn(false);
  };

  return (
    <Modal
      {...rest}
      onShow={turnoffSwitchBtn}
      backdrop='static'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => onHide()}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleClickBtn} disabled={switchBtn}>
          {switchBtn ?
            <Spinner animation='border' /> :
            btnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  modalTitle: PropTypes.string,
  btnText: PropTypes.string,
  children: PropTypes.object,
  clickBtn: PropTypes.func,
  onHide: PropTypes.func,
};
