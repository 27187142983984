import React, {useState, useEffect} from 'react';
import {
  Button, Row, Col, Spinner, ButtonGroup, ToggleButton,
} from 'react-bootstrap';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import TopicTitle from '../components/TopicTitle';
import MedicalRecordCard from '../components/MedicalRecordCard';
import medicalRecordsImage from '../assets/image/icon-records.png';

import * as MedicalRecordApi from '../api/MedicalRecordApi';

export default function MedicalRecords() {
  const radios = [
    {id: 0, name: 'Descending', value: 'descending'},
    {id: 1, name: 'Ascending', value: 'ascending'},
  ];
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [openedMedicalRecord, setOpenedMedicalRecord] = useState();
  const [retrieveMedicalRecords, setRetrieveMedicalRecords] = useState(false);
  const [sequence, setSepuence] = useState('descending');
  const [dateRange, setDateRange] = useState([
    dayjs().subtract('1', 'month').format('YYYY-MM-DD'),
    dayjs().add('1', 'month').format('YYYY-MM-DD')]);

  const handleChangeDateRange = (date) => {
    setDateRange([
      dayjs(date[0]).format('YYYY-MM-DD'),
      dayjs(date[1]).format('YYYY-MM-DD'),
    ]);
  };

  useEffect(() => {
    setRetrieveMedicalRecords(false);
    MedicalRecordApi.getAllMedicalRecord(sequence, dateRange)
      .then((medicalRecords) => {
        setRetrieveMedicalRecords(true);
        setMedicalRecords(medicalRecords);
      });
  }, [sequence, dateRange]);

  return (
    <main>
      <TopicTitle
        topicName='Medical or Ins. Records Storage'
        topicImage={medicalRecordsImage}
      />
      <p style={{marginBottom: 0}}>Display Order</p>
      <Row style={{display: 'flex', alignItems: 'center', height: '100%'}}>
        <Col xs={9}>
          <ButtonGroup style={{width: '100%'}}>
            {
              radios.map((radio, idx) => (
                <ToggleButton
                  key={radio.id}
                  id={idx}
                  type='radio'
                  variant='outline-primary'
                  name='radio'
                  value={radio.value}
                  checked={sequence === radio.value}
                  onChange={(e) => setSepuence(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))
            }
          </ButtonGroup>
        </Col>
        <Col xs={3}>
          <Link to='./add'>
            <Button className='carnation-btn'>+ Add</Button>
          </Link>
        </Col>
      </Row>
      <p style={{margin: '1rem 0 0 0'}}>Filter by Date</p>
      <DateRangePicker onChange={handleChangeDateRange} value={dateRange} />
      {
        retrieveMedicalRecords ?
          (
            <ul className='list-style-none'>
              {
                medicalRecords.map((medicalRecord) => (
                  <MedicalRecordCard
                    key={medicalRecord.id}
                    openedMedicalRecord={openedMedicalRecord}
                    setOpenedMedicalRecord={setOpenedMedicalRecord}
                    medicalRecords={medicalRecords}
                    setMedicalRecords={setMedicalRecords}
                    medicalRecord={medicalRecord}
                  />
                ))
              }
            </ul>
          ) :
          (
            <div style={{textAlign: 'center'}}>
              <Spinner animation='border' variant='dark' />
            </div>
          )
      }
    </main>
  );
}
