import React, {useState, useEffect} from 'react';
import {
  Container, Form, Row, Col, Button, Image,
} from 'react-bootstrap';
import {useParams, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import * as OralPhotoApi from '../api/OralPhotoApi';

export default function OralPhotosEditor() {
  const {oralPhotoId} = useParams();
  const navigate = useNavigate();
  const [oralPhoto, setOralPhoto] = useState({
    id: '1',
    shoot_date: dayjs().format('YYYY-MM-DD'),
    photo_type: 'X-ray',
    image_link: '',
    note: '',
  });

  useEffect(() => {
    OralPhotoApi.getOralPhotoById(oralPhotoId).then(setOralPhoto);
  }, [oralPhotoId]);

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setOralPhoto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clickUpdatePhoto = () => {
    OralPhotoApi.updateOralPhoto(oralPhotoId, oralPhoto).then(() => {
      navigate('/oral-photo/');
    });
  };

  return (
    <Container>
      <h2 className='page-title'>Edit Photo</h2>
      <Form>
        <Form.Group as={Row} className='mb-3' controlId='formPlaintextEmail'>
          <Form.Label column xs={2}>
            Date
          </Form.Label>
          <Col xs={10}>
            <Form.Control
              type='date'
              value={oralPhoto.shoot_date}
              name='shoot_date'
              onChange={handleChangeForm}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3' controlId='formPlaintextEmail'>
          <Form.Label column xs={2}>
            Type
          </Form.Label>
          <Col xs={10}>
            <Form.Select
              name='photo_type'
              value={oralPhoto.photo_type}
              onChange={handleChangeForm}
            >
              <option value='X-ray'>X-ray</option>
              <option value='Photo'>Photo</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <div>
          <Image src={oralPhoto.image_link} id={`${oralPhoto.id}image`} />
        </div>
        <Form.Control
          as='textarea'
          rows={7}
          placeholder='Add some notes...'
          name='note'
          onChange={handleChangeForm}
          value={oralPhoto.note}
        />
      </Form>
      <div style={{padding: '0 1rem', marginTop: '1rem'}}>
        <Button
          className='melrose-btn'
          onClick={clickUpdatePhoto}
        >
          Update Photo
        </Button>
      </div>
    </Container>
  );
}
