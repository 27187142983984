import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import dayjs from 'dayjs';

export default function ReservationDetailModal({
  show, reservation, answers, complaint, onHide, cancelReservation,
}) {
  const contentStyle = {
    borderRadius: '0.5rem',
    padding: '1rem',
  };

  return (
    <Modal
      show={show}
      backdrop='static'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <h3>Reservation Information</h3>
      </Modal.Header>
      <Modal.Body>
        {
          complaint && dayjs() > dayjs(reservation.reservation_time) &&
            <div style={{...contentStyle}}>
              <h5>Doctor summary</h5>
              <Row>
                <Col>
                  Condition
                </Col>
                <Col>
                  {complaint.doctor_condition}
                </Col>
              </Row>
              <Row>
                <Col>
                  Suggestion
                </Col>
                <Col>
                  {complaint.doctor_summary}
                </Col>
              </Row>
              <Row>
                <Col>
                  Recommended return visit time
                </Col>
                <Col>
                  {complaint.doctor_return}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  Doctor upload images
                </Col>
                <Col xs={12}>
                  {
                    complaint.doctor_images &&
                    complaint.doctor_images.split('||').map((image, index) =>
                      (
                        <div key={`image-${index}`}>
                          <img alt='' src={`${image}`} />
                        </div>
                      ),
                    )
                  }
                </Col>
              </Row>

              <hr />
            </div>
        }
        <div style={{...contentStyle, backgroundColor: '#EEEEEE'}}>
          <h5>Consultation Info.</h5>
          {
            answers.map((answer) => {
              return (
                <Row key={`${answer.id}`}>
                  <Col>
                    {answer.form_and_questions.question.question_label}
                  </Col>
                  <Col>
                    {answer.answer}
                  </Col>
                </Row>
              );
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
        {
          reservation && dayjs() < dayjs(reservation.reservation_time) &&
            <Button
              variant='warning'
              onClick={() => cancelReservation(reservation.id)}
            >
              Cancel Reservation
            </Button>
        }

      </Modal.Footer>
    </Modal>
  );
}

ReservationDetailModal.propTypes = {
  show: PropTypes.bool,
  reservation: PropTypes.object,
  complaint: PropTypes.object,
  answers: PropTypes.array,
  onHide: PropTypes.func,
  cancelReservation: PropTypes.func,
};
