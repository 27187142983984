import React from 'react';

export default function HippaNoticeOfPrivacy() {
  return (
    <>
      <div>
        <h2>HIPAA Notice of Privacy Practices</h2>
        <section>
          <p><b>
            THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT
            YOU MAY BE USED AND DISCLOSED AND
            HOW YOU CAN GET ACCESS TO THIS INFORMATION.
          </b></p>
          <p><b>PLEASE REVIEW IT CAREFULLY.</b></p>
          <p>
            In this notice we use the terms
            &quot;we,&quot; &quot;us,&quot; and &quot;our&quot;
            to describe Agape Medtecs Inc.
            (hereinafter “Dental Book”).
            For more details, please refer to section IV of this notice.
          </p>
        </section>

        <section>
          <h4>I. WHAT IS &quot;PROTECTED HEALTH INFORMATION&quot;?</h4>
          <p>
            Your protected health information (PHI)
            is individually identifiable health information,
            including demographic information, about your past,
            present or future physical or mental health or condition,
            health care services you receive, and past, present or
            future payment for your health care. Demographic information
            means information such as your name, social security
            number, address, and date of birth.
          </p>
          <p>
            PHI may be in oral, written or electronic form.
            Examples of PHI include your medical record,
            claims record, enrollment or disenrollment information,
            and communications between you and your health care
            provider about your care.
          </p>
          <p>
            If your PHI is de-identified in
            accordance with HIPAA standards, it is no longer PHI.
          </p>
        </section>

        <section>
          <h4>II. ABOUT OUR RESPONSIBILITY TO PROTECT YOUR PHI</h4>
          <p>By law, we must</p>
          <ol>
            <li>protect the privacy of your PHI;</li>
            <li>
              tell you about your rights and our
              legal duties with respect to your PHI;
            </li>
            <li>
              notify you if there is a breach
              of your unsecured PHI; and
            </li>
            <li>
              tell you about our privacy practices
              and follow our notice currently in effect.
            </li>
          </ol>
          <p>
            We take these responsibilities seriously and,
            have put in place administrative safeguards
            (such as security awareness training and policies and procedures),
            technical safeguards (such as encryption and passwords),
            and physical safeguards (such as locked areas)
            to protect your PHI and, as in the past,
            we will continue to take appropriate steps to
            safeguard the privacy of your PHI.
          </p>
        </section>

        <section>
          <h4>III. YOUR RIGHTS REGARDING YOUR PHI</h4>
          <p>
            This section tells you about your rights regarding
            your PHI and describes how you can exercise these rights.
          </p>
          <h5>Your right to access and amend your PHI</h5>
          <p>
            Subject to certain exceptions, you have the right to
            view or get a copy of your PHI that we maintain in records.
            Subject to certain exceptions, requests must be in writing.
            We may charge you a reasonable, cost-based fee for the copies,
            summary or explanation of your PHI.
          </p>
          <p>
            If we do not have the record you asked for but we know who does,
            we will tell you who to contact to request it.
            In limited situations, we may deny some or all of your request
            to see or receive copies of your records, but if we do,
            we will tell you why in writing and explain your right,
            if any, to have our denial reviewed.
          </p>
          <p>
            If you believe there is a mistake in your PHI or that
            important information is missing, you may request that
            we correct or add to the record. Requests must be in writing,
            tell us what corrections or additions you are requesting,
            and why the corrections or additions should be made.
            We will respond in writing after reviewing your request.
            If we approve your request we will make the correction or
            addition to your PHI. If we deny your request, we will
            tell you why and explain your right to file a written
            statement of disagreement. Your statement must be limited
            to 250 words for each item in your record that you believe
            is incorrect or incomplete. You must clearly tell us in
            writing if you want us to include your statement in future
            disclosures we make of that part of your record.
            We may include a summary instead of your statement.
          </p>
          <p>
            Submit all written requests to the Dental Book via email:
            privacy@aidentalbook.com or
            mail: 10440 S. De Anza Blvd, Suite D4, Cupertino, CA 95014.
          </p>
          <h5>
            Your right to choose how we send PHI to you or someone else
          </h5>
          <p>
            Your PHI is stored electronically.  You may request
            a copy of the records in an electronic format emailed
            to you by Dental Book. You may also make a specific
            written request to Dental Book to transmit a copy of
            your PHI to a designated third party.
            We may charge a reasonable, cost-based fee.
          </p>
          <h5>
            Your right to receive confidential communications
          </h5>
          <p>
            You have the right to request that we communicate
            with you about health matters at an alternative
            mailing address, email address, or telephone number.
          </p>
          <h5>
            Your right to an accounting of disclosures of PHI
          </h5>
          <p>
            You may ask us for a list of our disclosures
            of your PHI. Email to us at privacy@aidentalbook.com.
            You are entitled to one disclosure accounting in
            any 12-month period at no charge. If you request
            any additional accountings less than
            12 months later, we may charge a fee.
          </p>
          <p>
            An accounting does not include certain
            disclosures, for example, disclosures:
          </p>
          <ul>
            <li>
              to carry out diagnosis, treatment,
              payment and health care operations;
            </li>
            <li>for which Dental Book had a signed authorization;</li>
            <li>of your PHI to you;</li>
            <li>for notifications for disaster relief purposes;</li>
            <li>
              to persons involved in your care and
              persons acting on your behalf; or
            </li>
            <li>not covered by the right to an accounting.</li>
          </ul>
          <h5>
            Your right to request limits on uses and disclosures of your PHI
          </h5>
          <p>
            You may request that we limit our uses and disclosures
            of your PHI for diagnosis, treatment, payment, and health
            care operations purposes. We will review and consider your
            request. You may email to the Dental Book at
            privacy@aidentalbook.com.
          </p>
          <p>
            We are not required to agree to your request,
            except to the extent that you request a restriction
            on disclosures to a health plan or insurer for
            payment or health care operations purposes and the
            items or services have been paid for out of pocket
            in full. However, we can still disclose the information
            to a health plan or insurer for the purpose of treating you.
          </p>
          <p>
            You can ask us not to use or share certain health
            information for treatment, payment, or our operations.
            We are not required to agree to your request, and we may
            say ‘no’ if it would affect your care. We will consider
            all submitted requests and, if we deny your request,
            we will notify you in writing.
          </p>
          <h5>Your right to receive a paper copy of this notice</h5>
          <p>
            You also have a right to receive
            a paper copy of this notice upon request.
          </p>
        </section>

        <section>
          <h4>IV. HOW WE MAY USE AND DISCLOSE YOUR PHI</h4>
          <p>
            Your confidentiality is important to us. Our staff are
            required to maintain the confidentiality of the PHI of
            our members/patients, and we have policies and procedures
            and other safeguards to help protect your PHI from
            improper use and disclosure. Sometimes we are allowed
            by law to use and disclose certain PHI without your
            written permission. We briefly describe these uses
            and disclosures below and give you some examples.
          </p>
          <p>
            How much PHI is used or disclosed without your written
            permission will vary depending, for example, on the
            intended purpose of the use or disclosure. Sometimes
            we may only need to use or disclose a limited amount
            of PHI, such as to send you an appointment reminder
            or to confirm that you are a health plan member.
            At other times, we may need to use or disclose more
            PHI such as when we are providing medical treatment.
          </p>
          <ul>
            <li>
              Diagnostics: This is an important use and disclosure
              of your PHI. For example, our provider physicians,
              nurses, and other health care personnel, including
              trainees, involved in your care may use and disclose
              your PHI to diagnose your condition and evaluate your
              health care needs. Our personnel will use and disclose
              your PHI in order to provide and coordinate the care
              and services you need: including, but not limited to,
              prescriptions, X-rays, and lab work.
            </li>
            <li>
              Payment: Your PHI may be needed to determine your
              responsibility to pay for, or to permit us to bill
              and collect payment for, services that you receive.
            </li>
            <li>
              Health care operations: We may use and disclose
              your PHI for certain health care operations—for
              example, quality assessment and improvement,
              training and evaluation of health care professionals,
              licensing, accreditation.
            </li>
            <li>
              Business associates: We may contract with business
              associates to perform certain functions or activities
              on our behalf, such as payment and diagnosis.
              These business associates must agree to safeguard your PHI.
            </li>
            <li>
              Appointment reminders: We may use your PHI to
              contact you about appointments for treatment
              or other health care you may need.
            </li>
            <li>
              Identity verification: We may photograph you for
              identification purposes, storing the photo in your
              medical record. This is for your protection and
              safety, but you may opt out.
            </li>
            <li>
              Communications with family and others when you are
              present: Sometimes a family member or other person
              involved in your care will be present when we are
              discussing your PHI with you. If you object, please
              tell us and we won&apos;t discuss your PHI or we
              will ask the person to leave.
            </li>
            <li>
              Disclosure in case of disaster relief: We may disclose
              your name, city of residence, age, gender, and general
              condition to a public or private disaster relief
              organization to assist disaster relief efforts,
              unless you object at the time.
            </li>
            <li>
              Disclosures to parents as personal representatives
              of minors: In most cases, we may disclose your minor
              child&apos;s PHI to you. In some situations, however,
              we are permitted or even required by law to deny your
              access to your minor child&apos;s PHI. An example when
              we must deny access to parents is when minors have adult
              rights to make their own health care decisions.
              These minors include, for example, minors who were or
              are married or who have a declaration of emancipation
              from a court.
            </li>
            <li>
              Health oversight: As health care providers and health
              plans, we are subject to oversight conducted by federal
              and state agencies. These agencies may conduct audits of
              our operations and activities and in that process,
              they may review your PHI.
            </li>
            <li>
              Workers&apos; compensation:
              We may use and disclose your PHI in order to
              comply with workers’ compensation laws.
            </li>
            <li>
              Military activity and national security: We may sometimes
              use or disclose the PHI of armed forces personnel to the
              applicable military authorities when they believe it is
              necessary to properly carry out military missions.
              We may also disclose your PHI to authorized federal
              officials as necessary for national security and
              intelligence activities or for protection of the
              president and other government officials and dignitaries.
            </li>
            <li>
              Required by law: In some circumstances federal or
              state law requires that we disclose your PHI to others.
              For example, the secretary of the Department of Health
              and Human Services may review our compliance efforts,
              which may include seeing your PHI.
            </li>
            <li>
              Lawsuits and other legal disputes: We may use and
              disclose PHI in responding to a court or administrative
              order, a subpoena, or a discovery request. We may also
              use and disclose PHI to the extent permitted by law without
              your authorization, for example, to defend a lawsuit
              or arbitration.
            </li>
            <li>
              Law enforcement: We may disclose PHI to authorized
              officials for law enforcement purposes, for example,
              to respond to a search warrant, report a crime on
              our premises, or help identify or locate someone.
            </li>
            <li>
              Serious threat to health or safety: We may use and
              disclose your PHI if we believe it is necessary to
              avoid a serious threat to your health or safety or
              to someone else&apos;s.
            </li>
            <li>
              Abuse or neglect: By law, we may disclose PHI to the
              appropriate authority to report suspected child abuse
              or neglect or to identify suspected victims of abuse,
              neglect, or domestic violence.
            </li>
            <li>
              Coroners and funeral directors: We may disclose PHI to
              a coroner or medical examiner to permit identification
              of a body, determine cause of death, or for other official duties.
            </li>
            <li>
              Inmates: Under the federal law that requires us to give
              you this notice, inmates do not have the same rights to
              control their PHI as other individuals. If you are an
              inmate of a correctional institution or in the custody
              of a law enforcement official, we may disclose your PHI
              to the correctional institution or the law enforcement
              official for certain purposes, for example, to protect
              your health or safety or someone else&apos;s.
            </li>
            <li>
              De-Identification: We or a business associate with whom we
              have contracted may use PHI to de-identify it in accordance
              with HIPAA standards and may further disclose the de-identified
              data to third parties in connection with KP’s operations.
            </li>
          </ul>
        </section>

        <section>
          <h4>
            V. ALL OTHER USES AND DISCLOSURES OF
            YOUR PHI REQUIRE YOUR PRIOR WRITTEN AUTHORIZATION
          </h4>
          <p>
            Except for those uses and disclosures described above,
            we will not use or disclose your PHI without your written
            authorization. Some instances in which we may request
            your authorization for use or disclosure of PHI are:
          </p>
          <ul>
            <li>
              Marketing: We may ask for your authorization in order
              to provide information about products and services
              that you may be interested in purchasing or using.
              Marketing also does not include any discussions
              you may have with your providers about products or services.
            </li>
            <li>
              Sale of PHI: We may only sell your PHI if
              we received your prior written authorization to do so.
            </li>
          </ul>
          <p>
            When your authorization is required and you authorize
            us to use or disclose your PHI for some purpose,
            you may revoke that authorization by notifying us
            in writing at any time. Please note that the revocation
            will not apply to any authorized use or disclosure of
            your PHI that took place before we received your revocation.
          </p>
        </section>

        <section>
          <h4>
            VI. HOW TO CONTACT US ABOUT THIS NOTICE OR
            TO COMPLAIN ABOUT OUR PRIVACY PRACTICES
          </h4>
          <p>
            If you have any questions about this notice, or want to lodge
            a complaint about our privacy practices, please write to us
            at email address: privacy@aidentalbook.com.
          </p>
          <p>
            We will not take retaliatory action against
            you if you file a complaint about our privacy practices.
          </p>
        </section>

        <section>
          <h4>VII. CHANGES TO THIS NOTICE</h4>
          <p>
            We may change this notice and our privacy practices at any time,
            as long as the change is consistent with state and federal law.
            Any revised notice will apply both to the PHI we already have
            about you at the time of the change, and any PHI created or
            received after the change takes effect. If we make an important
            change to our privacy practices, we will promptly change this
            notice and make the new notice available on our Web site at
            www.aidentalbook.com /privacy. Except for changes required by
            law, we will not implement an important change to our privacy
            practices before we revise this notice.
          </p>
        </section>

        <section>
          <h4>VIII. EFFECTIVE DATE OF THIS NOTICE</h4>
          <p>This notice is effective on January 1, 2023.</p>
        </section>
      </div>
    </>
  );
}
