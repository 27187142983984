import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';

import MessageModal from '../components/MessageModal';
import Logo from '../components/Logo';

import {useAuth} from '../hooks/AuthContext';

export default function ForgetPassword() {
  const auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [registerData, setRegisterData] = useState({
    email: '',
  });
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [message, setMessage] = useState('');

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setRegisterData((prevRegisterData) => ({
      ...prevRegisterData,
      [name]: value,
    }));
  };

  const showMessage = (message) => {
    setMessage(message);
    setMessageModalShow(true);
  };

  const getForgetPasswordLink = (data) => {
    auth.forgetPassword(data).then(() => {
      showMessage(
        'Reset success! Please check ' +
        'your email to change the password');
    }).catch((err) => {
      showMessage(err.response.data.error);
    });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    const data = {
      email: registerData.email,
    };

    getForgetPasswordLink(data);
  };

  return (
    <main>
      <center>
        <Logo />
        <div className='central-container'>
          <Form validated={validated} onSubmit={clickSubmit}>
            <Form.Group className='mb-3' controlId='formEmail'>
              <Form.Label>
                Please enter your email to reset password
              </Form.Label>
              <Form.Control
                name='email'
                type='email'
                placeholder='Email'
                onChange={handleChangeForm}
                autoComplete='on'
                required
              />
            </Form.Group>
            <Button
              className='melrose-btn'
              style={{marginTop: '2rem'}}
              type='submit'
            >
              Reset Password
            </Button>
          </Form>
        </div>
      </center>
      <MessageModal
        show={messageModalShow}
        onHide={() => setMessageModalShow(false)}
        textmessage={message}
      />
    </main>
  );
}
