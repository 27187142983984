import React from 'react';
import {Outlet} from 'react-router-dom';
import {Container} from 'react-bootstrap';

export default function ContainerPadding() {
  return (
    <Container>
      <Outlet />
    </Container>
  );
}
