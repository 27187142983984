import React from 'react';
import TopicTitle from '../components/TopicTitle';
import TopicCard from '../components/TopicCard';
import medicalRecordsImage from '../assets/image/icon-records.png';
import virtualConsultationImage from '../assets/image/icon-virtual.png';

const virtualConsultationFunctions = [
  {
    id: '0',
    topicKey: 'reservation',
    topicName: 'Reservation',
    topicImage: virtualConsultationImage,
    topicBgColor: '#D0D6F6',
    location: `${process.env.REACT_APP_VIRTUAL_CONSULTATION_URL}/reservation/`,
  },
  {
    id: '1',
    topicKey: 'reservation-record',
    topicName: 'Reservation Record',
    topicImage: medicalRecordsImage,
    topicBgColor: '#D0D6F6',
    location: 'reservation-record',
  },
];

/**
 * Page Component for showing virtual consultation function list page.
 *
 * @component
 *
 * @return {JSX}
 * @example
 * <VirtualConsultation />
 */
export default function VirtualConsultation() {
  return (
    <>
      <TopicTitle
        topicName='Virtual Consultation'
        topicImage=''
      />
      <center>
        <ul className='list-style-none'>
          {
            virtualConsultationFunctions.map((topic) => (
              <TopicCard
                key={topic.id}
                topicKey={topic.topicKey}
                topicName={topic.topicName}
                topicBgColor={topic.topicBgColor}
                topicImage={topic.topicImage}
                topicLocation={topic.location}
              />
            ))
          }
        </ul>
      </center>
    </>
  );
}
