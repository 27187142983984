import React, {useState} from 'react';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import ConfirmModal from '../components/ConfirmModal';
import FileDisplayCard from '../components/FileDisplayCard';
import ChooseFile from '../components/ChooseFile';
import DentalBookPrivacyPolicy from '../components/DentalBookPrivacyPolicy';
import HippaNoticeOfPrivacy from '../components/HippaNoticeOfPrivacy';

import * as MedicalRecordApi from '../api/MedicalRecordApi';

export default function MedicalRecordsAdd() {
  const navigate = useNavigate();
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [newMedicalRecord, setNewMedicalRecord] = useState({
    title: '',
    record_date: dayjs().format('YYYY-MM-DD'),
    note: '',
    medical_files: [],
  });

  const handleRemoveFile = (fileName) => {
    setNewMedicalRecord((preMedicalRecord) => ({
      ...preMedicalRecord,
      medical_files: preMedicalRecord.medical_files
        .filter((file) => file.file_name !== fileName),
    }));
  };

  const handleUploadFile = (newFile) => {
    setNewMedicalRecord((preMedicalRecord) => ({
      ...preMedicalRecord,
      medical_files: [...preMedicalRecord.medical_files, ...newFile],
    }));
  };

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setNewMedicalRecord((prevMedicalRecord) => ({
      ...prevMedicalRecord,
      [name]: value,
    }));
  };

  const handleAddRecord = () => {
    MedicalRecordApi.createMedicalRecord(newMedicalRecord).then(() => {
      navigate('/medical-records/');
    });
  };

  return (
    <main>
      <h2 className='page-title'>Add New Records</h2>
      <div style={{marginTop: '1rem'}}>
        <Form>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column xs='2'>
              Title
            </Form.Label>
            <Col xs={10}>
              <Form.Control
                type='text'
                placeholder='A New Record Title...'
                name='title'
                value={newMedicalRecord.title}
                onChange={handleChangeForm}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className='mb-3'
            controlId='formPlaintextPassword'
          >
            <Form.Label column xs='2'>
              Date
            </Form.Label>
            <Col xs={10}>
              <Form.Control
                type='date'
                name='record_date'
                value={newMedicalRecord.record_date}
                onChange={handleChangeForm}
              />
            </Col>
          </Form.Group>
          <Form.Control
            as='textarea'
            rows={10}
            style={{backgroundColor: '#FFF'}}
            placeholder='add some note...'
            name='note'
            value={newMedicalRecord.note}
            onChange={handleChangeForm}
          />
        </Form>
      </div>
      <div style={{padding: '0 1rem', marginTop: '1rem'}}>
        <ul className='list-style-none'>
          {
            newMedicalRecord.medical_files.map((file, idx) => (
              <FileDisplayCard
                key={idx}
                fileName={file.file_name}
                fileLink={file.file_link}
                removeFile={handleRemoveFile}
              />
            ))
          }
        </ul>
        <ChooseFile
          height='5rem'
          accept='image/*,.pdf'
          addFile={handleUploadFile}
        />
        <Button
          className='melrose-btn'
          onClick={() => setConfirmModalShow(true)}
        >
          Add Record
        </Button>
      </div>
      <ConfirmModal
        modalTitle='Disclaimer'
        show={confirmModalShow}
        btnText='Add Medical Record'
        onHide={() => setConfirmModalShow(false)}
        clickBtn={() => handleAddRecord()}
      >
        <div style={{height: '65vh', overflowX: 'auto'}}>
          <DentalBookPrivacyPolicy />
          <HippaNoticeOfPrivacy />
        </div>
      </ConfirmModal>
    </main>
  );
}
