import React, {useState, useEffect} from 'react';
import {Image, Button} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import * as OralSupplierApi from '../api/OralSupplierApi';

export default function OralSupplierContent() {
  const {supplierId} = useParams();
  const [oralSupplier, setOralSupplier] = useState({});
  const [oralSupplierEssay, setOralSupplierEssay] = useState('');

  useEffect(() => {
    OralSupplierApi.getOralSupplier(supplierId)
      .then((res) => {
        setOralSupplier(res);
      });
  }, [supplierId]);

  useEffect(() => {
    OralSupplierApi.getOralSupplierMarkdown(oralSupplier.content_markdown)
      .then((res) => {
        setOralSupplierEssay(res);
      });
  }, [oralSupplier]);

  return (
    <section>
      <h2 className='page-title'>{oralSupplier.title}</h2>
      <div className='padding-content'>
        <Image
          src={oralSupplier.cover_image}
          style={{maxWidth: '100%', marginBottom: '1.5rem'}}
        />
        <ReactMarkdown>
          {oralSupplierEssay}
        </ReactMarkdown>
        <div style={{padding: '0 10vw'}}>
          <Button className='melrose-btn'>Buy</Button>
        </div>
      </div>
    </section>
  );
}
