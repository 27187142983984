import React, {useState, createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import * as AccountAPI from '../api/AccountApi';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  let [user, setUser] = useState(null);
  const [checkIsLogin, setCheckIsLogin] = useState(false);

  const login = (data, callback) => {
    return AccountAPI.login(data).then((token) => {
      sessionStorage.setItem('access', token.access);
      sessionStorage.setItem('refresh', token.refresh);
      return getAccountData(callback);
    }).catch((err) => {
      if (err.response) {
        return err.response.data;
      }
    });
  };

  const register = (data, callback) => {
    return AccountAPI.register(data).then(() => {
      callback();
    }).catch((err) => {
      if (err.response) {
        return err.response.data;
      }
    });
  };

  const getAccountData = (callback) => {
    if (user) {
      return user;
    } else {
      return AccountAPI.getAccountData().then((data) => {
        setUser(data);
        callback();
      }).finally(() => {
        setCheckIsLogin(true);
      });
    }
  };

  const updateAccountData = (accountData) => {
    AccountAPI.updateAccountData(accountData)
      .then((data) => {
        setUser({
          ...user,
          ...data,
        });
      });
  };

  const logout = (callback) => {
    sessionStorage.removeItem('access');
    user = null;
    callback();
  };

  const bindSocialMediaAccount = (socialMediaData) => {
    AccountAPI.bindSocialMediaAccount(socialMediaData).then((data) => {
      setUser(data);
    });
  };

  const deleteSocialMediaAccount = (socialMediaType, socialMediaId) => {
    AccountAPI.deleteSocialMediaAccount(socialMediaType, socialMediaId)
      .then((data) => {
        setUser(data);
      });
  };

  const forgetPassword = (emailData) => {
    return AccountAPI.requestForgetPasswordLink(emailData);
  };

  const updatePasswordWithKey = (passwordData) => {
    return AccountAPI.updatePasswordWithKey(passwordData);
  };

  const value = {
    user,
    checkIsLogin,
    login,
    register,
    logout,
    getAccountData,
    updateAccountData,
    bindSocialMediaAccount,
    deleteSocialMediaAccount,
    forgetPassword,
    updatePasswordWithKey,
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.object,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
