import React from 'react';

export default function TermsAndConditions() {
  return (
    <div>
      <section>
        <h4>Purpose</h4>
        <p>
          Agape MedTecs Inc.
          (hereinafter referred to as “AIDentalBook”)
          provides a website and a mobile app:
        </p>
        <ul>
          <li>
            &quot;iDentalBook.com&quot; and
            &quot;AIDentalBook.com&quot; (the Website)
          </li>
          <li>
            &quot;AIDentalbook Mobile Application&quot;
            (the App), for both iPhone and Android</li>
        </ul>
        <p>
          The Website and the App are referred to collectively
          in these Terms and Conditions as the &quot;Site.&quot;
        </p>
      </section>

      <section>
        <h4>The Site allows users to:</h4>
        <ul>
          <li>view dental health-related information</li>
          <li>communicate with our practitioners and our staff</li>
          <li>arrange for clinical and medical record storage services</li>
          <li>access additional services</li>
          <li>purchase dental hygiene products</li>
          <li>
            consent to receive important communications and documents
            (such as notices, videos and telephony)
            electronically through the email address and/or
            mobile number specified by the user for this purpose
          </li>
        </ul>
        <p>
          The information provided on the Site is not a substitute
          for the advice of a personal physician, dentist or other
          qualified health care professional and does not constitute
          a diagnosis or professional treatment recommendation.
          Always seek the advice of a physician, dentist or other
          qualified health care professional with any questions
          regarding medical symptoms or a medical condition.
          Never disregard professional medical advice or
          delay in seeking it because of something you have
          read on the Site.
        </p>
        <p>
          If you think you or someone you are taking care of
          has a medical or psychiatric emergency,
          call 911 or go to the nearest hospital.
        </p>
      </section>

      <section>
        <h4>Agreement</h4>
        <p>
          BY USING THE SITE OR BY CLICKING &quot;I ACCEPT&quot;
          BELOW, YOU SIGNIFY YOUR AGREEMENT TO THESE TERMS AND CONDITIONS.
          IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS,
          DO NOT USE THE SITE.
        </p>
      </section>

      <section>
        <h4>Using secure messaging</h4>
        <p>
          AIDentalBook provides users with a number of interactive
          online services to help them better communicate with
          our practitioners and staff.
        </p>
        <p>
          You agree that you will not upload or transmit any
          communications or content of any type
          (including secure messaging) that infringe upon,
          misappropriate or violate any rights of any party.
        </p>
      </section>

      <section>
        <h4>Uploading, Storing and Viewing medical records</h4>
        <p>
          Users may use the Site to upload, store, and view their medical
          records, including the results of certain laboratory tests.
          You acknowledge and agree that if you delete your medical records,
          it will be permanent as AIDentalBook does not keep a backup record.
        </p>
        <p>
          Users may also authorize other users of the Site to view their
          health information. If you are authorized to access another
          user&apos;s health information, you agree to protect the
          confidentiality of this information and comply with state
          and federal privacy laws that may prohibit the redisclosure
          of health information without the express written authorization
          of the person who is the subject of the health information,
          including but not limited to federal law prohibiting the
          redisclosure of health information regarding alcohol and
          drug abuse referral and treatment.
        </p>
        <p>
          Any personal information you submit to the Site
          (for yourself or someone else) is governed by our
          Website Privacy Policy. (A link to our Website Privacy Policy
          is available at the bottom of each page on our Website and
          on the &quot;Home&quot; screen at the bottom of the AIDentalBook
          Mobile Application.) This includes information on your rights
          to see and receive copies of your own or others&apos;
          personal health information.
        </p>
      </section>

      <section>
        <h4>
          AIDentalBook Mobile Application:
          &quot;Jailbreaking&quot; the mobile operating system
        </h4>
        <p>
          The AIDentalBook Mobile Application is intended for use only
          on a mobile device that is running an unmodified
          manufacturer-approved operating system. Using the
          AIDENTALBOOK Mobile Application on a device with a
          modified operating system may undermine security
          features that are intended to protect health information
          from unauthorized or unintended disclosure.
          You may compromise your health information or the health
          information of anyone for whom you have been given access,
          if you use the AIDENTALBOOK Mobile Application on a mobile
          device that has been modified. Use of the AIDENTALBOOK
          Mobile Application on a mobile device with a modified operating
          system is a material breach of these Terms and Conditions.
        </p>
      </section>

      <section>
        <h4>Passwords and Biometric Authentication</h4>
        <p>
          AIDentalBook has several tools that allow you to upload,
          record and store information. You are responsible for taking
          all reasonable steps to ensure that no unauthorized person
          shall have access to your AIDentalBook online password or account.
          It is your sole responsibility to
          (1) control the disclosure and use of your activation codes,
          password reset codes and password;
          (2) authorize, monitor, and control access to and use of your
          AIDentalBook online account and password; and
          (3) promptly change your password if you feel it
          has become compromised; and
          (4) promptly inform the Site manager of any need to deactivate
          an account entirely.
        </p>
        <p>
          If you forget your Website password, among the available methods for
          resetting your password is the option to choose to have a one-time
          password reset code sent to either the email address you provided
          to us when you registered for the Website or via SMS/Text on your
          primary mobile number on file. To protect your privacy, we recommend
          that you not use an email address or mobile device that you
          share with anyone else.
        </p>
        <p>
          If you enable biometric authentication
          (e.g., iOS or iPadOS Face ID, Touch ID or Android Fingerprint)
          to sign on to the App, it is your responsibility to be
          aware that all faces or fingerprints set up on your device
          for such biometric authentication will be linked to your
          Website account associated with the App. If other people
          use the biometric authentication method enabled on your device,
          they may be able to gain access to your AIDentalBook account
          via the App and view information you consider to be private.
          It is your responsibility to understand the risks of biometric
          authentication enabled on your mobile device.
        </p>
      </section>

      <section>
        <h4>Breaches of these Terms and Conditions</h4>
        <p>
          In consideration of being allowed to use the Site
          interactive services, you agree that the following
          actions shall constitute a material breach of these
          Terms and Conditions:
        </p>
        <ul>
          <li>
            signing on as or pretending to be another person without
            this person’s permission
          </li>
          <li>
            using messaging for any purpose in violation of local,
            state, national, international laws or posted AIDentalBook policies
          </li>
          <li>
            transmitting material that infringes or violates
            the intellectual property rights of others or the
            privacy or publicity rights of others
          </li>
          <li>
            transmitting material that is unlawful, obscene, defamatory,
            predatory of minors, threatening, harassing, abusive, slanderous,
            or hateful to any person (including AIDentalBook personnel)
            or entity as determined by AIDentalBook in its sole discretion
          </li>
          <li>
            using interactive services in a way that is intended to harm,
            or a reasonable person would understand would likely
            result in harm, to the user or others
          </li>
          <li>
            collecting information about others, including email addresses
          </li>
          <li>
            intentionally distributing viruses or other harmful computer code
          </li>
          <li>
            attempting to (1) probe, scan, &quot;hack&quot;,
            or test the vulnerability of the Site or any AIDentalBook
            system or network; or (2) breach any security or
            authentication measures on the Site or any AIDentalBook
            system connected to either the Site
          </li>
          <li>
            using any &quot;deep-link&quot;, &quot;page-scrape&quot;,
            &quot;robot&quot;, &quot;spider&quot;, data mining tools,
            data gathering and extraction tools, or other automatic
            device, program, algorithm or methodology, to (1) access,
            acquire, copy or monitor any portion of the Site, or (2)
            in any way reproduce or circumvent the navigational
            structure or presentation of the Site
          </li>
          <li>
            &quot;Jailbreaking&quot;
            the operating system of a mobile device using the App
          </li>
        </ul>
        <p>
          AIDentalBook expressly reserves the right, in its sole
          discretion, to terminate a user&apos;s access to any
          services and/or to any or all other areas of the Site
          due to any act that would constitute a violation of
          these Terms and Conditions. To the extent there is an
          inconsistency between these Terms and Conditions and
          the Website and AIDENTALBOOK Mobile Application Privacy
          Statement, these Terms and Conditions shall govern.
        </p>
        <p>
          AIDentalBook may offer interactive areas of the Site
          under license with Third Party software service providers.
          These Terms and Conditions extend to and apply to your use
          of our licensors’ software. Our licensors are direct
          third-party beneficiaries of applicable rights under
          these Terms and Conditions. By using our licensor’s
          software, you agree to be bound directly to our
          licensors with respect to your compliance with these
          Terms and Conditions. AIDentalBook may directly enforce the
          licensors’ rights under these Terms and Conditions on the
          licensors’ behalf or a licensor may enforce its rights under
          these Terms and Conditions directly against you or other
          users who breach these Terms and Conditions.
        </p>
      </section>

      <section>
        <h4>Communications by email</h4>
        <p>
          By accepting these Terms and Conditions,
          you agree to receive essential communications
          by email. These communications may include (but are not limited to):
        </p>
        <ul>
          <li>
            notification that an important message awaits you on the Site
            (this may be a message from a doctor, an
            appointment confirmation, a message from a product vendor, etc.)
          </li>
          <li>Site and/or service updates</li>
          <li>emergency alerts and critical messages</li>
          <li>
            general health communications from AIDentalBook and its providers
          </li>
        </ul>
        <p>
          You should take appropriate precautions to protect
          personal and confidential information, and to use
          your devices/apps in a secure and responsible manner.
          We are not responsible for the security of your devices,
          and we expect that you will configure them in a secure
          and responsible manner.
        </p>
        <p>
          We will use a secure transmission method to send you communications
          that include protected health information
          (as that term is defined under HIPAA) through e-mail,
          our Website, and/or Mobile App. While such secure
          transmission methods provide reasonable protections
          against unauthorized access, no system can perfectly
          guard against risks of intentional intrusion or
          inadvertent disclosure of information sent to us.
          Moreover, when you transmit information via the internet,
          your information will be transmitted over a medium that is
          beyond our control, and therefore the security of the
          transmission may be compromised before it reaches us.
          Accordingly, we make no guarantee as to confidentiality
          or security. If you have concerns regarding the
          electronic transmission of protected health information,
          you should consider using non-electronic communication
          methods to receive documents relating to health plan coverage.
        </p>
        <p>
          All AIDentalBook staff who have access to, or are involved with,
          the processing of personal information are trained to respect
          the confidentiality of your personal information.
        </p>
      </section>

      <section>
        <h4>Communications by Phone or Text</h4>
        <p>
          You verify that any phone information provided to AIDENTALBOOK
          is true and accurate, and that you are the current subscriber
          or owner of any telephone number that you provide.
        </p>
        <p>
          You acknowledge that by voluntarily providing your
          telephone number(s), you expressly agree to receive
          prerecorded voice messages and/or autodialed calls,
          and text messages from us, our agents, affiliates,
          and independent contractors related to your relationship
          with AIDENTALBOOK. You acknowledge that automated calls
          or text messages may be made to your telephone number(s)
          even if your telephone number(s) is registered on any
          state or federal Do Not Call list. You agree to receive
          automated calls and text messages from AIDENTALBOOK,
          our agents, affiliates, and independent contractors
          even if you cancel your account or terminate your
          relationship with us, except if you opt-out (see below).
        </p>
        <p>
          The text message communications that you may
          receive from AIDENTALBOOK may include (but are not limited to):
        </p>
        <ul>
          <li>
            A one-time, multifactor authentication (MFA)
            passcode sent to you after you have opted-in to
            receiving such a code to authenticate your
            logging onto the Site
          </li>
          <li>
            General health communications from AIDentalBook
            and/or its providers including but not limited to
            notifications regarding upcoming or scheduled appointments,
            checkups and exams, upload or storage of medical records,
            lab results, prescription notifications or reminders,
            or home healthcare instructions
          </li>
          <li>
            General health communications relating to your account,
            registration, changes and updates, service outages,
            reminders, billing notifications, payment notices,
            new services available to customers, or any
            transaction with AIDENTALBOOK
          </li>
          <li>Marketing and promotional communications</li>
        </ul>
        <p>
          At any time, and even if you have expressly given us
          permission to send voice/text messages to you, you may
          communicate your desire not to receive additional
          voice/text messages by following the stop or opt-out
          instructions in the voice or text message, by adjusting
          your preferences on the Website
          (for only certain categories of communications),
          or by contacting customer services.
        </p>
        <p>
          You acknowledge that neither AIDentalBook nor
          its providers are liable for delayed or undelivered messages.
        </p>
        <p>
          As always, message and data rates may apply for any
          messages sent to you from us and to us from you.
          If you have any questions about your text plan or
          data plan, it is best to contact your wireless provider.
        </p>
        <p>
          You acknowledge that you are aware text messages are
          not fully secure and could be intercepted by a third
          party and acknowledge if you share your mobile device
          with others, they may be able to view text messages
          sent by AIDentalBook. It is your responsibility to
          understand the risks of receiving text messages on
          your mobile device.
        </p>
      </section>

      <section>
        <h4>Use by children</h4>
        <p>
          We do not knowingly allow minors under the age of 13
          to create accounts that allow access to the secured
          features of the Site.
        </p>
      </section>

      <section>
        <h4>Access, correction, and data integrity</h4>
        <p>
          Although we attempt to maintain the integrity and
          accuracy of the information on the Site, we make no
          guarantees as to its correctness, completeness, or accuracy.
          The Site may contain typographical errors, inaccuracies,
          or other errors or omissions. Also, unauthorized additions,
          deletions, or alterations could be made to the Site by third
          parties without our knowledge. If you believe that information
          found on the Site is inaccurate or unauthorized, please inform
          us by contacting our Site Manager.
        </p>
      </section>

      <section>
        <h4>Revisions, changes, and updates</h4>
        <p>
          We may revise the information on the Site or otherwise
          change or update the Site, including these Terms and
          Conditions, without notice to you. AIDentalBook may
          also make improvements and/or changes in products
          and/or services described on the Site or add new
          features at any time without notice. We encourage
          you to periodically read these Terms and Conditions
          to see if there have been any changes to our policies
          that may affect you. Your continued use of the Site will
          signify your continued agreement to these Terms and
          Conditions as they may be revised.
        </p>
      </section>

      <section>
        <h4>Links to Third Party Websites</h4>
        <p>
          The Site provides links to other websites that
          are not owned or controlled by AIDentalBook
          (&quot;Third Party Websites&quot;). We provide
          links to Third Party Websites as follows.
        </p>
        <ul>
          <li>
            AIDentalBook provides links to Third Party Websites
            to connect you easily to additional sources of health
            information or third party services that may be of
            interest to you. We may not have any business relationship
            with the party that controls this type of Third Party
            Website and a link to such a site is offered
            only as a convenience to you.
          </li>
          <li>
            AIDentalBook also provides links to Third Party
            Websites managed by vendors that we have made
            arrangements with to offer you services to help
            you manage your health or to take and fulfill
            orders when you purchase items or materials from us.
            These Third Party Websites may be co-branded, meaning
            that they display the AIDentalBook logo and the logo
            of the Third Party vendor but they are owned and
            controlled by the Third Party.
          </li>
        </ul>
        <p>
          In each such instance, where practicable, we will let
          you know when you are leaving the Site and linking to
          a Third Party Website. We may use an icon that we identify
          with an appropriate legend to let you know when you
          are leaving the Website.
        </p>
        <p>
          AIDentalBook may not be responsible for the content,
          security or the privacy practices of Third Party Websites.
          Please review the privacy statement and any terms of use
          of each Third Party Website you visit, if applicable.
          Unless we specifically advise you otherwise, links to
          Third Party Websites do not constitute or imply endorsement
          by AIDentalBook of those sites, the information they contain
          or any products or services they describe. AIDentalBook does
          not receive payment or other remuneration in exchange for you
          linking to or using any Third Party Website.
        </p>
      </section>

      <section>
        <h4>Deletion (Deactivation) of Your Online Account</h4>
        <p>
          If you request that we delete (deactivate) your online account,
          we will block access to your online account, but we will not
          delete any data or other information of the AIDentalBook
          applications or websites associated with that online account.
          You will not be able to use any AIDentalBook mobile applications
          or websites that use the same user ID and password associated
          with the online account. If you wish to reactivate your online
          account to regain access to such AIDentalBook mobile applications
          or websites, please call customer services. With a deleted
          (deactivated) online account you may no longer be able to
          receive copies of your medical records.
        </p>
      </section>

      <section>
        <h4>This section pertains to the App only.</h4>
        <p>
          Consent to Electronic (Paperless) Health Plan
          Coverage Communications via Website and App
        </p>
        <p />
      </section>

      <section>
        <h4>This section pertains to the App only.</h4>
        <p>
          Consent to Electronic (Paperless) Health
          Plan Coverage Communications via Website and App
        </p>
        <p>
          By accepting the terms and conditions and agreeing
          to this consent to receive communications
          (all available ones or certain chosen ones)
          electronically, you consent (agree) to receive
          essential health plan coverage communications via
          the Website and/or Mobile App. These communications
          may include (but are not limited to):
        </p>
        <ul>
          <li>
            Benefits: Communications and documents
            pertaining to your customer benefits.
          </li>
          <li>
            Billing: Communications and documents related
            to billing for your AIDentalBook services.
          </li>
          <li>
            Other types of communication: Other types of communications
            may include but are not limited to: Communications about
            your health savings account (HSA), member satisfaction
            and other surveys (participation is optional),
            quality information, and other notices that may be required by law.
          </li>
        </ul>
      </section>

      <section>
        <h4>Electronic Delivery of Communications</h4>
        <p>
          You will receive communications and/or
          documents electronically from AIDentalBook.
        </p>
        <h5>A. Electronic Delivery Method</h5>
        <p>
          All communications and documents relating to AIDentalBook
          services will be made available electronically through the Website.
          All communications that we provide to you in electronic form,
          will be provided either (1) via email; (2) via the Website as
          described in an email notice we send to you at the time the
          information is available; (3) to the extent permissible by law,
          by access to a Third-Party Website as described in advance for
          such purpose in an email notice we send to you at the time the
          information is available; or (4) by an email requesting that
          you access or download a PDF file from the Website containing
          the document.
        </p>
        <h5>B. Notification that you have a New Communication</h5>
        <p>
          When a new document or communication is delivered electronically
          through the Website, you will be notified by email to the primary
          email address found in your Website profile. If AIDentalBook
          receives a notification of delivery failure (i.e. a “bounce message”)
          that indicates that the primary email address which we have on file
          for you is no longer valid, we will suspend electronic delivery of
          all documents and communications and may mail your communications
          and/or documents to the last known mailing address on file.
        </p>
        <h5>C. Hardware and Software Requirements</h5>
        <p>
          In order to access and retain your electronic documents,
          you will need the following computer hardware and software:
        </p>
        <ul>
          <li>A computer with an internet connection</li>
          <li>A current web browser</li>
          <li>
            Current Adobe Acrobat Reader version to open documents in PDF format
          </li>
          <li>
            A valid email address
            (your primary email address in your Website profile)
          </li>
          <li>
            Sufficient storage space to save past
            documents or an installed printer to print them
          </li>
        </ul>
        <p>
          By giving your consent at this time, you agree that you
          have access to the necessary hardware and software as
          listed above, and are able to receive, open, and print
          or download a copy of any document for your records.
        </p>
        <p>
          We will notify you if there are any material changes
          to the hardware or software needed to receive electronic
          documents from AIDentalBook at least 30 days in advance
          of the date any such changes are made. At that time, we
          will also provide instructions on how to withdraw, change,
          or renew your consent for electronic delivery of your documents.
        </p>
        <h5>D. Updating your Contact Information</h5>
        <p>
          It is your responsibility to keep your primary email
          address up to date so that AIDentalBook can
          communicate with you electronically.
        </p>
        <p>
          You understand and agree that if AIDentalBook sends
          you an email notification that a communication and/or
          document was sent and/or an electronic document has been
          posted, and we do not receive a notification of delivery
          failure (i.e. a “bounce message”), AIDentalBook will be
          deemed to have provided the communication to and/or
          document you. This could occur if, for example, you do
          not receive it because your primary email address on file
          is blocked by your service provider, or you are otherwise
          unable to receive electronic communications.
        </p>
        <p>
          Please note that if you use a spam filter that
          blocks or re-routes emails from senders not listed
          in your email address book, you must add the AIDentalBook
          email address to your email address book so that you will
          be able to receive the communications we send to you
          (please consult the instructions of your spam filter
          for specific directions on how to do this).
        </p>
        <p>
          You can update your primary email address or mailing
          address at any time through your Website profile.
        </p>
        <h5>E. How to Withdraw your Consent</h5>
        <p>
          Your consent will remain in effect until you explicitly
          withdraw your consent by emailing or mailing to us.
        </p>
      </section>

      <section>
        <h4>Disclaimer and Limitation of liability</h4>
        <h5>Disclaimer of Warranties</h5>
        <p>
          THE SITE, AND ANY CONTENT, INFORMATION, SERVICES OR PRODUCTS OBTAINED
          THROUGH THE SITE IS PROVIDED &quot;AS IS,&quot; WITH ALL FAULTS,
          WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
          EITHER EXPRESSED OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
          OR NON¬ INFRINGEMENT. YOUR USE OF THE SITE IS VOLUNTARY,
          AND AT YOUR OWN RISK. ANY REFERENCES TO SPECIFIC PRODUCTS
          OR SERVICES ON THE SITE DOES NOT CONSTITUTE OR IMPLY A
          RECOMMENDATION OR ENDORSEMENT OF SUCH PRODUCTS OR SERVICES BY
          AIDENTALBOOK UNLESS SPECIFICALLY STATED OTHERWISE.
        </p>
        <h5>Limitation of liability</h5>
        <p>
          AIDENTALBOOK AND ITS AFFILIATES, SUPPLIERS, LICENSORS AND
          OTHER THIRD PARTIES MENTIONED OR LINKED TO ON THE SITE ARE
          NEITHER RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE,
          OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, THOSE
          RESULTING FROM LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION)
          ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, AND ANY CONTENT,
          INFORMATION, SERVICES OR PRODUCTS OBTAINED THROUGH THE SITE, ANY
          APP TO WHICH YOU PERMIT AIDENTALBOOK TO RELEASE DATA (AND INCLUDING,
          WITHOUT LIMITATION, THE APP&apos;S USE OF ANY DATA YOU MAKE
          AVAILABLE TO THE APP), AND/OR ANY THIRD PARTY WEBSITE, OR YOUR
          USE OF ANY OF THE FOREGOING, WHETHER BASED ON WARRANTY, CONTRACT,
          TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. EXCEPT AS DESCRIBED IN THE
          FOLLOWING PARAGRAPH, YOUR SOLE REMEDY FOR DISSATISFACTION WITH
          THE SITE, SITE-RELATED SERVICES, APPS, AND/OR THIRD PARTY
          WEBSITES IS TO STOP USING THE SITE, APPS, AND/OR THOSE SERVICES.
        </p>
        <p>
          APPLICABLE LAWS MAY NOT ALLOW SUCH DISCLAIMER OF
          WARRANTIES, LIMITATIONS OF LIABILITY, OR THE EXCLUSIONS
          FROM SUCH LIABILITY, AND YOU MAY BE ENTITLED TO SEEK
          OTHER REMEDIES UNDER YOUR EVIDENCE OF COVERAGE OR OTHER
          AGREEMENT WITH AIDENTALBOOK PERMANENTE. IN SUCH CASE,
          THE ABOVE DISCLAIMERS, LIMITATIONS OR EXCLUSIONS MAY
          NOT APPLY TO YOU.
        </p>
      </section>

      <section>
        <h4>Choice of law</h4>
        <p>
          THESE TERMS AND CONDITIONS ARE GOVERNED BY CALIFORNIA LAW WITHOUT
          REGARD TO ITS PRINCIPLES OF CONFLICTS OF LAW. IF ANY VERSION OF
          THE UNIFORM COMPUTER INFORMATION TRANSACTIONS ACT (UCITA) IS ENACTED
          AS PART OF THE LAW OF CALIFORNIA, THAT STATUTE SHALL NOT GOVERN ANY
          ASPECT OF THESE TERMS AND CONDITIONS.
        </p>
        <p>
          Any rights not expressly granted by these Terms and Conditions or
          any applicable end-user license agreements are reserved by
          AIDentalBook Permanente. Content and features are subject to
          change or termination without notice in the editorial discretion
          of AIDentalBook.
        </p>
        <p>
          The Digital Millennium Copyright Act of 1998 (the &quot;DMCA&quot;)
          provides recourse for copyright owners who believe that material
          appearing on the Internet infringes their rights under U.S. copyright
          law. If you believe in good faith that materials appearing on the Site
          infringe your copyright, you (or your agent) may send us a notice
          requesting that the material be removed, or access to it blocked.
        </p>
        <p>
          In addition, if you believe in good faith that a notice
          of copyright infringement has been wrongly filed against you,
          the DMCA permits you to send us a counter-notice.
          Notices and counter-notices must meet statutory
          requirements imposed by the DMCA. One place to find more
          information is the U.S. Copyright Office website,
          currently located at https://www.copyright.gov.
        </p>
        <p>
          In accordance with the DMCA, AIDentalBook has
          designated an agent to receive notification
          of alleged copyright infringement in accordance
          with the DMCA. Any written Notification of Claimed
          Infringement should comply with Title 17,
          United States Code, Section 512(c)(3)(A)
          and should be provided in writing to
          our designated agent as follows:
        </p>
        <ul style={{listStyleType: 'none'}}>
          <li>Agent: AIDentalBook Copyright Compliance Department</li>
          <li>Address: 10440 S. De Anza Blvd Suite D4, Cupertino, CA 95014</li>
          <li>Email: copyright@aidentalbook.com</li>
        </ul>
      </section>
    </div>
  );
}
