import axios from 'axios';
import {getToken} from './AccountApi';

const request = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/oral-photo/`,
  headers: {
    Accept: 'application/json',
  },
});

request.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

request.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      window.location.href = '/login';
    }
    return err;
  },
);

export const getAllOralPhotos = (photoType, dateRange) =>
  request.get(
    `?photoType=${photoType}` +
    `&startDate=${dateRange[0]}` +
    `&endDate=${dateRange[1]}`,
  ).then((res) => res.data);

export const getOralPhotoById = (oralPhotoId) =>
  request.get(`${oralPhotoId}/`).then((res) => res.data);
export const createOralPhoto = (oralPhotoData) =>
  request.post('', oralPhotoData).then((res) => res.data);
export const updateOralPhoto = (oralPhotoId, oralPhotoData) =>
  request.put(`${oralPhotoId}/`, oralPhotoData);
export const deleteOralPhoto = (oralPhotoId) =>
  request.delete(`${oralPhotoId}/`);
