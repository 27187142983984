import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import {
  UilEdit, UilAngleUp, UilAngleDown,
} from '@iconscout/react-unicons';
import FileDisplayCard from './FileDisplayCard';

import * as MedicalRecordApi from '../api/MedicalRecordApi';

export default function MedicalRecordCard({
  openedMedicalRecord, setOpenedMedicalRecord,
  setMedicalRecords, medicalRecord,
}) {
  const handleRemoveFile = (medicalFileId) => {
    MedicalRecordApi.deleteMedicalFile(medicalRecord.id, medicalFileId)
      .then(() => {
        setMedicalRecords(
          (preMedicalRecords) => preMedicalRecords.map((record) => {
            if (record.id !== medicalRecord.id) {
              return record;
            }
            return {
              ...record,
              medical_files: record.medical_files.filter(
                (file) => file.id !== medicalFileId),
            };
          }),
        );
      });
  };

  return openedMedicalRecord !== medicalRecord.id ?
    (
      <li className='accordion-card'>
        <div
          className='accordion-card-body'
          onClick={() => setOpenedMedicalRecord(medicalRecord.id)}
        >
          <div style={{width: '90%'}}>
            <p style={{margin: '0'}}>{medicalRecord.record_date}</p>
            <h4
              style={{margin: '0', fontWeight: 'bold'}}
            >
              {medicalRecord.title}
            </h4>
          </div>
          <div style={{width: '10%'}}>
            <UilAngleDown className='unicon' />
          </div>
        </div>
      </li>
    ) :
    (
      <li key={medicalRecord.id} className='accordion-card'>
        <div
          className='accordion-card-body'
          style={{
            flexDirection: 'column',
          }}
        >
          <div style={{width: '100%'}}>
            <div
              className='accordion-card-title'
              onClick={() => setOpenedMedicalRecord('')}
            >
              <p
                style={{margin: '0', width: '90%'}}
              >
                {medicalRecord.record_date}
              </p>
              <span style={{width: '10%'}}>
                <UilAngleUp className='unicon' />
              </span>
            </div>
            <div className='accordion-card-title'>
              <h4
                style={{margin: '0', fontWeight: 'bold', width: '80%'}}
              >
                {medicalRecord.title}
              </h4>
              <Link
                id={`${medicalRecord.id}-edit`}
                style={{width: '10%'}}
                to={{pathname: `./${medicalRecord.id}`}}
              >
                <UilEdit className='unicon' />
              </Link>
            </div>
          </div>
          <div style={{width: '100%', marginBottom: '0.5rem'}}>
            <Form.Control
              as='textarea'
              rows={3}
              style={{backgroundColor: '#E5E5E5'}}
              value={medicalRecord.note}
              readOnly
            />
          </div>
          <div style={{width: '100%', margin: '0 0 1rem 0'}}>
            <ul className='list-style-none'>
              {medicalRecord.medical_files.map((file, idx) => (
                <FileDisplayCard
                  key={idx}
                  id={file.id}
                  fileName={file.file_name}
                  fileLink={file.file_link}
                  removeFile={() => handleRemoveFile(file.id)}
                  canDownload
                />
              ))}
            </ul>
          </div>
        </div>
      </li>
    );
}

MedicalRecordCard.propTypes = {
  medicalRecord: PropTypes.object.isRequired,
  openedMedicalRecord: PropTypes.string.isRequired,
  setMedicalRecords: PropTypes.func.isRequired,
  setOpenedMedicalRecord: PropTypes.func.isRequired,
};
