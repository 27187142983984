import React from 'react';
import {Container, Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import oralPhotoImage from '../assets/image/icon-photos.png';
import appointmentsImage from '../assets/image/icon-appointments.png';
import medicalRecordsImage from '../assets/image/icon-records.png';
import dentalKnowledgeImage from '../assets/image/icon-knowledgement.png';
import oralSuppliersImage from '../assets/image/icon-suppliers.png';
import virtualConsultationImage from '../assets/image/icon-virtual.png';
import dentalAiImage from '../assets/image/icon-doctorAi.png';

import TopicCard from '../components/TopicCard';

import {useAuth} from '../hooks/AuthContext';

const topics = [
  {
    id: 0,
    topicKey: 'oral-photos',
    topicName: 'X-rays and Photos Storage',
    topicImage: oralPhotoImage,
    topicBgColor: '#D0D6F6',
    location: '/oral-photo',
  },
  {
    id: 6,
    topicKey: 'dental-ai',
    topicName: 'Dental AI',
    topicImage: dentalAiImage,
    topicBgColor: '#D0D6F6',
    location: '/dental-ai',
  },
  {
    id: 3,
    topicKey: 'dental-knowledge',
    topicName: 'Dental & Tx Knowledgement',
    topicImage: dentalKnowledgeImage,
    topicBgColor: '#D0D6F6',
    location: '/dental-knowledge',
  },
  {
    id: 1,
    topicKey: 'appointments',
    topicName: 'Appointments Remind & History',
    topicImage: appointmentsImage,
    topicBgColor: '#D0D6F6',
    location: '/appointments',
  },
  {
    id: 2,
    topicKey: 'medical-records',
    topicName: 'Medical or Ins. Records Storage',
    topicImage: medicalRecordsImage,
    topicBgColor: '#D0D6F6',
    location: '/medical-records',
  },
  {
    id: 4,
    topicKey: 'oral-supplier',
    topicName: 'Oral Hyglene Suppliers Knowledgement',
    topicImage: oralSuppliersImage,
    topicBgColor: '#D0D6F6',
    location: '/oral-supplier',
  },
  {
    id: 5,
    topicKey: 'virtual-consultation',
    topicName: 'Virtual Consultation',
    topicImage: virtualConsultationImage,
    topicBgColor: '#D0D6F6',
    location: '/virtual-consultation',
  },
];

export default function Home() {
  const auth = useAuth();
  const navigate = useNavigate();

  const clickLogout = () => {
    auth.logout(() => {
      navigate('/login');
    });
  };

  return (
    <Container>
      <h2 className='page-title'>
        Hi,&nbsp;
        {auth.user.username}
      </h2>
      <center>
        <ul className='list-style-none'>
          {
            topics.map((topic) => (
              <TopicCard
                key={topic.id}
                topicKey={topic.topicKey}
                topicName={topic.topicName}
                topicBgColor={topic.topicBgColor}
                topicImage={topic.topicImage}
                topicLocation={topic.location}
              />
            ))
          }
        </ul>
        <Button
          variant='outline-light'
          onClick={clickLogout}
          style={{
            backgroundColor: '#FAA7B8',
            marginBottom: '1rem',
          }}
        >
          Log out
        </Button>
      </center>
    </Container>
  );
}
