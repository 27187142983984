import React from 'react';
import PropTypes from 'prop-types';
import {UilTrash, UilArrowToBottom} from '@iconscout/react-unicons';

export default function FileDisplayCard(props) {
  return (
    <li className='blueberry-card' style={{width: '100%', textAlign: 'left'}}>
      <p
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <span style={{
          width: '70%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        >
          <span>{props.fileName}</span>
        </span>
        <span
          style={{width: '10%'}}
          onClick={() => props.removeFile(props.fileName)}
        >
          <UilTrash className='unicon' />
        </span>
        {props.canDownload &&
          (<a
            style={{width: '10%'}}
            href={props.fileLink}
            rel='noreferrer noopener'
          >
            <UilArrowToBottom className='unicon' />
          </a>)}
      </p>
    </li>
  );
}

FileDisplayCard.propTypes = {
  fileName: PropTypes.string,
  fileLink: PropTypes.string,
  canDownload: PropTypes.bool,
  removeFile: PropTypes.func,
};
