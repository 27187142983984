import React from 'react';
import banner from './assets/image/DB-logo-banner.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Outlet, Link} from 'react-router-dom';
import {UilEstate, UilUserCircle} from '@iconscout/react-unicons';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <div className='flex-container'>
          <Link to='/' className='header-link'>
            <UilEstate />
          </Link>
        </div>
        <div className='flex-container'>
          <img src={banner} className='App-logo' alt='banner' />
        </div>
        <div className='flex-container'>
          <Link to='/account' className='header-link'>
            <UilUserCircle />
          </Link>
        </div>
      </header>
      <Outlet />
    </div>
  );
}

export default App;
