import axios from 'axios';
import {getToken} from './AccountApi';

const request = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/appointment/`,
  headers: {
    Accept: 'application/json',
  },
});

request.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

request.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      window.location.href = '/login';
    }
    return err;
  },
);

export const getAllAppointment = (order, dateRange) =>
  request.get(
    `?order=${order}` +
    `&startDate=${dateRange[0]}` +
    `&endDate=${dateRange[1]}`,
  ).then((res) => res.data);

export const getAppointmentById = (appointmentId) =>
  request.get(`${appointmentId}/`).then((res) => res.data);

export const createAppointment = (appointmentData) =>
  request.post('', appointmentData).then((res) => res.data);

export const updateAppointment = (appointmentId, appointmentData) =>
  request.put(`${appointmentId}/`, appointmentData);

export const deleteAppointment = (appointmentId) =>
  request.delete(`${appointmentId}/`);
