import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import {UilEdit, UilTrash, UilArrowToBottom} from '@iconscout/react-unicons';

import FileDisplayCard from '../components/FileDisplayCard';
import ConfirmModal from '../components/ConfirmModal';
import ChooseFile from '../components/ChooseFile';

import * as MedicalRecordApi from '../api/MedicalRecordApi';

export default function MedicalRecordsEdit() {
  const {medicalRecordId} = useParams();
  const navigate = useNavigate();
  const [medicalRecord, setMedicalRecord] = useState({
    title: '',
    record_date: '',
    note: '',
    medical_files: [],
  });
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);

  const displayConfirmDeleteModal = () => {
    setDeleteConfirmShow(true);
  };

  const deleteMedicalRecord = () => {
    MedicalRecordApi.deleteMedicalRecord(medicalRecordId).then(() => {
      setDeleteConfirmShow(false);
      navigate('/medical-records/');
    });
  };

  const clickUpdateMedicalRecord = () => {
    MedicalRecordApi.updateMedicalRecord(medicalRecordId, medicalRecord)
      .then(() => {
        navigate('/medical-records/');
      });
  };

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setMedicalRecord((prevMedicalRecord) => ({
      ...prevMedicalRecord,
      [name]: value,
    }));
  };

  const handleRemoveFile = (medicalFileId) => {
    MedicalRecordApi.deleteMedicalFile(medicalRecordId, medicalFileId)
      .then(() => {
        setMedicalRecord((preMedicalRecord) => ({
          ...preMedicalRecord,
          medical_files: preMedicalRecord.medical_files.filter(
            (file) => file.id !== medicalFileId),
        }));
      });
  };

  const handleUploadFile = (newFile) => {
    newFile.forEach((file) => {
      MedicalRecordApi.createMedicalFile(medicalRecordId, file)
        .then((newMedicalFile) => {
          setMedicalRecord((preMedicalRecord) => ({
            ...preMedicalRecord,
            medical_files: [...preMedicalRecord.medical_files, newMedicalFile],
          }));
        });
    });
  };

  useEffect(() => {
    MedicalRecordApi.getMedicalRecordById(medicalRecordId)
      .then(setMedicalRecord);
  }, [medicalRecordId]);

  return (
    <main>
      <h2 className='page-title'>Medical or Ins. Records Storage</h2>
      <div style={{padding: '0 0 0 1rem', marginTop: '1rem'}}>
        <p style={{marginBottom: '0'}}>{medicalRecord.record_date}</p>
        <h3
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div style={{width: '70%'}}>
            {medicalRecord.title}
          </div>
          <div style={{width: '10%'}}>
            <UilArrowToBottom />
          </div>
          <div style={{width: '10%'}} onClick={clickUpdateMedicalRecord}>
            <UilEdit />
          </div>
          <div style={{width: '10%'}} onClick={displayConfirmDeleteModal}>
            <UilTrash />
          </div>
        </h3>
      </div>
      <div style={{marginTop: '1rem'}}>
        <Form>
          <Form.Control
            as='textarea'
            rows={10}
            name='note'
            style={{backgroundColor: '#FFF'}}
            value={medicalRecord.note}
            onChange={handleChangeForm}
          />
        </Form>
      </div>
      <div style={{padding: '0 1rem', marginTop: '1rem'}}>
        <h4>Attached Files</h4>
        <ul className='list-style-none'>
          {
            medicalRecord.medical_files.map((file, idx) => (
              <FileDisplayCard
                key={idx}
                id={file.id}
                fileName={file.file_name}
                fileLink={file.file_link}
                removeFile={() => handleRemoveFile(file.id)}
                canDownload
              />
            ))

          }
        </ul>
        <ChooseFile
          height='5rem'
          accept='image/*,.pdf'
          addFile={handleUploadFile}
        />
      </div>
      <ConfirmModal
        modalTitle='Delete Medical Record'
        show={deleteConfirmShow}
        btnText='Delete'
        onHide={() => setDeleteConfirmShow(false)}
        clickBtn={() => deleteMedicalRecord()}
      >
        <p style={{fontSize: '1.2rem'}}>Are you sure to delete?</p>
      </ConfirmModal>
    </main>
  );
}
