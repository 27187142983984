import axios from 'axios';

const request = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/`,
  headers: {
    Accept: 'application/json',
  },
});

export const getToken = () =>
  sessionStorage.getItem('access');

export const login = (loginData) =>
  request.post('/account/login/', loginData).then((res) => res.data);

export const register = (registerData) =>
  request.post('/account/register/', registerData);

export const logout = () =>
  request.get('/account/logout/');

export const getAccountData = () =>
  request.get('/account/', {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  }).then((res) => res.data);

export const updateAccountData = (accountData) =>
  request.put('/account/', accountData, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  }).then((res) => res.data);

export const requestForgetPasswordLink = (emailData) =>
  request.post('/account/forget-password/', emailData)
    .then((res) => res.data);

export const updatePasswordWithKey = (passwordData) =>
  request.post('/account/update-password/', passwordData)
    .then((res) => res.data);

export const deleteAccount = () =>
  request.delete('/account/', {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

export const bindSocialMediaAccount = (accountData) =>
  request.post('/account/social_media_account/', accountData, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  }).then((res) => res.data);

export const deleteSocialMediaAccount =
  (socialMediaType, socialMediaAccountId) =>
    request.delete(
      '/account/social_media_account/' +
      `${socialMediaType}/${socialMediaAccountId}/`,
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.data);
