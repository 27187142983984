import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';

export default function MessageModal(props) {
  const {textmessage, onHide, ...rest} = props;
  return (
    <Modal
      {...rest}
      backdrop='static'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header />
      <Modal.Body>
        <center style={{fontSize: '1.5rem'}}>{textmessage}</center>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MessageModal.propTypes = {
  textmessage: PropTypes.string,
  onHide: PropTypes.func,
};
