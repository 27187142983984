import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useGoogleLogin} from '@react-oauth/google';
import {useAuth} from '../hooks/AuthContext';
import {Button, Image} from 'react-bootstrap';
import icon from '../assets/image/icon-google.png';

export default function GoogleLoginBtn(props) {
  const auth = useAuth();
  const navigate = useNavigate();

  const loginGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => responseGoogle(credentialResponse),
  });

  const responseGoogle = (googleUser) => {
    const token = googleUser.access_token;
    const data = {
      login_type: 'google',
      token,
    };
    auth.login(data, () => {
      navigate(props.from, {replace: true});
    });
  };

  return (
    <>
      <Button
        className='login-btn'
        variant='outline-light'
        onClick={loginGoogle}
        disabled={!props.disabled}
      >
        <Image src={icon} className='login-icon' />
        <span style={{verticalAlign: 'bottom'}}>Sign in with Google</span>
      </Button>
    </>
  );
}

GoogleLoginBtn.propTypes = {
  from: PropTypes.string,
  disabled: PropTypes.bool,
};
