import React from 'react';
import PropTypes from 'prop-types';
import AppleSignin from 'react-apple-signin-auth';
import {useAuth} from '../hooks/AuthContext';
import {useNavigate} from 'react-router-dom';
import {Button, Image} from 'react-bootstrap';
import icon from '../assets/image/icon-apple.png';

export default function AppleLoginBtn({from, disabled}) {
  const auth = useAuth();
  const navigate = useNavigate();

  const responseApple = (appleUser) => {
    const data = {
      login_type: 'apple',
      token: appleUser.authorization.id_token,
    };
    auth.login(data, () => {
      navigate(from, {replace: true});
    });
  };

  return (
    <>
      <AppleSignin
        authOptions={{
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          scope: 'email name',
          redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
          state: '',
          nonce: 'nonce',
          usePopup: true,
        }}
        uiType='dark'
        className='apple-auth-btn'
        buttonExtraChildren='continue with Apple'
        onSuccess={(response) => responseApple(response)}
        render={(props) =>
          <Button
            {...props}
            className='login-btn'
            variant='outline-light'
            disabled={!disabled}
          >
            <Image src={icon} className='login-icon' />
            <span style={{verticalAlign: 'bottom'}}>Sign in with Apple</span>
          </Button>}
      />
    </>
  );
}

AppleLoginBtn.propTypes = {
  from: PropTypes.string,
  disabled: PropTypes.bool,
};
