import axios from 'axios';
import {getToken} from './AccountApi';

const request = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/medical-record/`,
  headers: {
    Accept: 'application/json',
  },
});

request.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

request.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      window.location.href = '/login';
    }
    return err;
  },
);

export const getAllMedicalRecord = (sequence, dateRange) =>
  request.get(
    `?sequence=${sequence}` +
    `&startDate=${dateRange[0]}` +
    `&endDate=${dateRange[1]}`,
  ).then((res) => res.data);

export const getMedicalRecordById = (medicalRecordId) =>
  request.get(`${medicalRecordId}/`).then((res) => res.data);

export const createMedicalRecord = (medicalRecordData) =>
  request.post('', medicalRecordData).then((res) => res.data);

export const updateMedicalRecord = (medicalRecordId, medicalRecordData) =>
  request.put(`${medicalRecordId}/`, medicalRecordData);

export const deleteMedicalRecord = (medicalRecordId) =>
  request.delete(`${medicalRecordId}/`);

export const createMedicalFile = (medicalRecordId, medicalFileData) =>
  request.post(`${medicalRecordId}/medical-file/`, medicalFileData)
    .then((res) => res.data);

export const deleteMedicalFile = (medicalRecordId, medicalFileId) =>
  request.delete(`${medicalRecordId}/medical-file/${medicalFileId}/`);
