import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Form, Image} from 'react-bootstrap';
import {
  UilEdit, UilTrash, UilAngleUp,
  UilAngleDown, UilArrowToBottom,
} from '@iconscout/react-unicons';

export default function OralPhotoCard({
  oralPhoto, openedOralPhoto, setOpenedOralPhoto, displayConfirmDeleteModal,
}) {
  const deleteOralPhoto = () => {
    displayConfirmDeleteModal();
  };

  return openedOralPhoto !== oralPhoto.id ?
    (
      <li key={oralPhoto.id} className='accordion-card'>
        <div
          className='accordion-card-body'
          onClick={() => setOpenedOralPhoto(oralPhoto.id)}
        >
          <div style={{width: '25%'}}>
            <Image src={oralPhoto.image_link} />
          </div>
          <div style={{width: '60%'}}>
            <p style={{margin: '0'}}>{oralPhoto.date}</p>
            <h4
              style={{margin: '0', fontWeight: 'bold'}}
            >
              {oralPhoto.photo_type}
            </h4>
          </div>
          <div style={{width: '10%'}}>
            <UilAngleDown className='unicon' />
          </div>
        </div>
      </li>
    ) :
    (
      <li key={oralPhoto.id} className='accordion-card'>
        <div
          className='accordion-card-body'
          style={{
            flexDirection: 'column',
          }}
        >
          <div style={{width: '100%'}}>
            <div
              className='accordion-card-title'
              onClick={() => setOpenedOralPhoto('')}
            >
              <p style={{margin: '0', width: '90%'}}>{oralPhoto.shoot_date}</p>
              <span style={{width: '10%'}}>
                <UilAngleUp className='unicon' />
              </span>
            </div>
            <div className='accordion-card-title'>
              <h4
                style={{margin: '0', fontWeight: 'bold', width: '80%'}}
              >
                {oralPhoto.photo_type}
              </h4>
              <span style={{width: '10%'}}>
                <a
                  href={oralPhoto.image_link}
                  style={{color: '#474747'}}
                  download
                >
                  <UilArrowToBottom className='unicon' />
                </a>
              </span>
              <span style={{width: '10%'}} onClick={deleteOralPhoto}>
                <UilTrash className='unicon' />
              </span>
              <Link
                id={`${oralPhoto.id}-edit`}
                style={{width: '10%', color: '#474747'}}
                to={{pathname: `./${oralPhoto.id}/edit`}}
              >
                <UilEdit className='unicon' />
              </Link>
            </div>
          </div>
          <div style={{width: '100%', margin: '1rem 0'}}>
            <Image src={oralPhoto.image_link} />
          </div>
          <div style={{width: '100%', marginBottom: '1rem'}}>
            <Form.Control
              as='textarea'
              rows={3}
              style={{backgroundColor: '#E5E5E5'}}
              value={oralPhoto.note}
              readOnly
            />
          </div>
        </div>
      </li>
    );
}

OralPhotoCard.propTypes = {
  oralPhoto: PropTypes.object,
  openedOralPhoto: PropTypes.string,
  setOpenedOralPhoto: PropTypes.func,
  displayConfirmDeleteModal: PropTypes.func,
};
