import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  UilEdit, UilTrash, UilMedicalSquare,
  UilCalendarAlt, UilClockEight,
} from '@iconscout/react-unicons';

export default function AppointmentCard({
  id, note, appointmentDate, displayConfirmDeleteModal,
}) {
  const deleteAppointment = () => {
    displayConfirmDeleteModal();
  };

  return (
    <li className='appointment-card'>
      <div style={{padding: '0.5rem 1.5rem'}}>
        <UilCalendarAlt />
        <span className='appointment-card-title'>
          {dayjs(appointmentDate).format('MM-DD-YYYY ddd')}
        </span>
      </div>
      <div
        className='appointment-card-body'
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          backgroundColor: '#FFF',
        }}
      >
        <p
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            margin: '0',
          }}
        >
          <span style={{width: '80%'}}>
            <UilClockEight className='unicon' />
            <span style={{color: '#474747'}}>
              {dayjs(appointmentDate).format('hh:mm A')}
            </span>
          </span>
          <span style={{width: '20%'}}>
            <span onClick={deleteAppointment}>
              <UilTrash className='unicon' />
            </span>
            <Link
              id={`${id}-edit`}
              style={{width: '10%'}}
              to={{pathname: `./${id}/edit`}}
            >
              <UilEdit className='unicon' />
            </Link>
          </span>
        </p>
        <hr style={{margin: '0.5rem'}} />
        <div style={{height: '5rem'}}>
          <span>
            <UilMedicalSquare className='unicon' />
          </span>
          <span style={{color: '#474747'}}>{note}</span>
        </div>
      </div>
    </li>
  );
}

AppointmentCard.propTypes = {
  id: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  appointmentDate: PropTypes.string.isRequired,
  displayConfirmDeleteModal: PropTypes.func.isRequired,
};
