import React from 'react';
import PropTypes from 'prop-types';
import {useGoogleLogin} from '@react-oauth/google';
import {UilLink} from '@iconscout/react-unicons';
import {useAuth} from '../hooks/AuthContext';

export default function GooglebindingIcon(props) {
  const auth = useAuth();

  const loginGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => bindGoogleAccount(credentialResponse),
  });

  const bindGoogleAccount = (googleUser) => {
    const data = {
      login_type: 'google',
      token: googleUser.access_token,
    };
    auth.bindSocialMediaAccount(data);
  };

  const deleteGoogleAccount = () => {
    auth.deleteSocialMediaAccount(
      'google',
      props.socialAccount.social_media_account);
  };

  return (
    <>
      {
        props.socialAccount ?
          <a
            className='blueberry-icon'
            onClick={deleteGoogleAccount}
          ><UilLink />
          </a> :
          <a onClick={loginGoogle}><UilLink /></a>
      }

    </>
  );
}

GooglebindingIcon.propTypes = {
  socialAccount: PropTypes.object,
};
