import React, {useState} from 'react';
import {
  Row, Form, Button, Image,
} from 'react-bootstrap';

import TopicTitle from '../components/TopicTitle';
import ChooseFile from '../components/ChooseFile';
import DentalAiResult from '../components/DentalAiResult';
import dentalAiImage from '../assets/image/icon-doctorAi.png';

import * as DentalAiApi from '../api/DentalAiApi';

export default function DentalAi() {
  const dentalAiList = [
    {
      name: 'Caries',
      value: 'caries',
    }, {
      name: 'Wisdom Teeth (Panel X-Ray Only)',
      value: 'wisdom_teeth',
    },
  ];
  const [oralXray, setOralXray] = useState({
    dental_ai: 'caries',
    image_url: null,
  });
  const [dentalAiResult, setDentalAiResult] = useState(null);

  const handleChangeForm = (e) => {
    const {name, value} = e.target;
    setOralXray((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const uploadOralXrayImage = (newImage) => {
    setOralXray((prevState) => ({
      ...prevState,
      image_url: newImage[0].file_link,
    }));
  };

  const clickStartDetection = () => {
    DentalAiApi.runDentalAi(oralXray).then((res) => {
      setDentalAiResult(res);
    });
  };

  const retryDentalAi = () => {
    setOralXray({
      ...oralXray,
      image_url: null,
    });
    setDentalAiResult(null);
  };

  return (
    dentalAiResult ?
      (
        <DentalAiResult
          dentalAiResult={dentalAiResult}
          retryDentalAi={retryDentalAi}
          dentalAi={oralXray.dental_ai}
        />
      ) :
      (
        <main>
          <TopicTitle
            topicName='Dental AI'
            topicImage={dentalAiImage}
          />
          <Form style={{padding: '0 2rem'}} className='dental-ai-form'>
            <Form.Group as={Row} className='mb-3' controlId='formDentalAiType'>
              <Form.Label column xs={12}>
                1. Choose the Detection type:
              </Form.Label>
              {
                dentalAiList.map((denatlAi) => (
                  <Form.Check
                    key={denatlAi.name}
                    label={denatlAi.name}
                    name='dental_ai'
                    value={denatlAi.value}
                    type='radio'
                    onChange={handleChangeForm}
                    style={{marginLeft: '2rem'}}
                    id={`${denatlAi.value}`}
                  />
                ))
              }
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formDentalAiType'>
              <Form.Label column xs={12}>
                2. Upload a photo:
              </Form.Label>
              {
                oralXray.image_url ?
                  <Image src={oralXray.image_url} /> :
                  <ChooseFile height='15rem' addFile={uploadOralXrayImage} />
              }
            </Form.Group>
          </Form>
          <div style={{padding: '0 1rem', marginTop: '1rem'}}>
            <Button
              className='melrose-btn'
              onClick={clickStartDetection}
            >
              Start Detection
            </Button>
          </div>
        </main>
      )
  );
}
