import axios from 'axios';
import {getToken} from './AccountApi';

const request = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/dental-ai/`,
  headers: {
    Accept: 'application/json',
  },
});

request.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

request.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      window.location.href = '/login';
    }
    return err;
  },
);

export const runDentalAi = (oralImage) =>
  request.post('', oralImage).then((res) => res.data);
