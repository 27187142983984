import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {UilPlus} from '@iconscout/react-unicons';

ChooseFile.propTypes = {
  height: PropTypes.string,
  accept: PropTypes.string,
  addFile: PropTypes.func,
};

export default function ChooseFile(props) {
  const inputFileEle = useRef(null);

  const selectFile = () => {
    inputFileEle.current.click();
  };

  const readFile = async (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve({
        file_name: file.name,
        file_link: event.target.result,
      });
    };
    reader.readAsDataURL(file);
  });

  const onFileChangeCapture = async (e) => {
    const newFileArray = [];
    for (const file of e.target.files) {
      newFileArray.push(await readFile(file));
    }
    props.addFile(newFileArray);
  };

  return (
    <div
      className='dashed-btn'
      style={{height: `${props.height}`}}
      onClick={selectFile}
    >
      <div>
        <UilPlus />
      </div>
      <p style={{marginBottom: '0'}}>click to choose file</p>
      <input
        ref={inputFileEle}
        id='image-select'
        type='file'
        name='file'
        style={{display: 'none'}}
        onChangeCapture={onFileChangeCapture}
        accept={props.accept}
      />
    </div>
  );
}
