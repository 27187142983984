import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from '@greatsumini/react-facebook-login';
import {UilLink} from '@iconscout/react-unicons';
import {useAuth} from '../hooks/AuthContext';

export default function FacebookbindingIcon(props) {
  const auth = useAuth();

  const bindFacebookAccount = (facebookUser) => {
    const data = {
      login_type: 'facebook',
      token: facebookUser.accessToken,
    };
    auth.bindSocialMediaAccount(data);
  };

  const deleteFacebookAccount = () => {
    auth.deleteSocialMediaAccount(
      'facebook',
      props.socialAccount.social_media_account);
  };

  return (
    <>
      {
        props.socialAccount ?
          <a
            className='blueberry-icon'
            onClick={deleteFacebookAccount}
          >
            <UilLink />
          </a> :
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            onSuccess={
              (response) => {
                bindFacebookAccount(response);
              }
            }
            onFail={
              (error) => {
                console.log('Login Failed!', error);
              }
            }
            onProfileSuccess={
              (response) => {
                console.log('Get Profile Success!', response);
              }
            }
            dialogParams={{
              response_type: 'token',
            }}
            render={
              ({onClick}) => (
                <a onClick={onClick}><UilLink /></a>
              )
            }
          />
      }
    </>
  );
}

FacebookbindingIcon.propTypes = {
  socialAccount: PropTypes.object,
};
