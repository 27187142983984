import React, {useState, useEffect} from 'react';
import TopicTitle from '../components/TopicTitle';
import OralSupplierList from '../components/OralSupplierList';
import oralSuppliersImage from '../assets/image/icon-suppliers.png';

import * as OralSupplierApi from '../api/OralSupplierApi';

export default function OralSuppliers() {
  const [oralSuppliers, setOralSuppliers] = useState([]);

  useEffect(() => {
    OralSupplierApi.getAllOralSupplier().then((res) => {
      setOralSuppliers(res);
    });
  }, []);

  return (
    <main>
      <TopicTitle
        topicName='Oral Hyglene Suppliers Knowledgement'
        topicImage={oralSuppliersImage}
      />
      <ul className='list-style-none'>
        {
          oralSuppliers.map((oralSupplier) => (
            <OralSupplierList
              key={oralSupplier.id}
              id={oralSupplier.id}
              title={oralSupplier.title}
              image={oralSupplier.cover_image}
            />
          ))
        }
      </ul>
    </main>
  );
}
