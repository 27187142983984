import axios from 'axios';
import {getToken} from './AccountApi';

const oralSupplierRequest = axios.create({
  baseURL: `https://${process.env.REACT_APP_HOST_DOMAIN}/api/dental-knowledge/`,
  headers: {
    Accept: 'application/json',
  },
});

oralSupplierRequest.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

oralSupplierRequest.interceptors.response.use(
  (response) => response,
  (err) => {
    if ([401, 403].includes(err.response.status)) {
      window.location.href = '/login';
    }
    return err;
  },
);

const markdownRequest = axios.create({
  mode: 'no-cors',
  crossDomain: true,
});

export const getAllDentalKnowledge = () =>
  oralSupplierRequest.get().then((res) => res.data);

export const getDentalKnowledge = (dentalKnowledgeId) =>
  oralSupplierRequest.get(`/${dentalKnowledgeId}/`).then((res) => res.data);

export const getDentalKnowledgeMarkdown = (url) =>
  markdownRequest.get(url).then((res) => res.data);
