import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  Row, Col, Button, ButtonGroup, ToggleButton, Spinner,
} from 'react-bootstrap';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import dayjs from 'dayjs';

import TopicTitle from '../components/TopicTitle';
import AppointmentCard from '../components/AppointmentCard';
import appointmentsImage from '../assets/image/icon-appointments.png';
import ConfirmModal from '../components/ConfirmModal';

import * as AppointmentApi from '../api/AppointmentApi';

export default function Appointments() {
  const radios = [
    {id: 0, name: 'Upcoming', value: 'upcoming'},
    {id: 1, name: 'Previous', value: 'previous'},
  ];
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(false);
  const [radioValue, setRadioValue] = useState('upcoming');
  const [dateRange, setDateRange] = useState(
    [
      dayjs().format('YYYY-MM-DD'),
      dayjs().add('1', 'month').format('YYYY-MM-DD'),
    ],
  );
  const [appointments, setAppointments] = useState([]);
  const [retrieveAppointments, setRetrieveAppointments] = useState(false);

  const handleChangeDateRange = (date) => {
    setDateRange([
      dayjs(date[0]).format('YYYY-MM-DD'),
      dayjs(date[1]).format('YYYY-MM-DD'),
    ]);
  };

  const displayConfirmDeleteModal = (appointmentId) => {
    setSelectedAppointment(appointmentId);
    setDeleteConfirmShow(true);
  };

  const deleteAppointment = () => {
    AppointmentApi.deleteAppointment(selectedAppointment).then(() => {
      setAppointments(
        (preAppointmenss) => preAppointmenss.filter(
          (appointment) => appointment.id !== selectedAppointment,
        ),
      );
      setDeleteConfirmShow(false);
    });
  };

  useEffect(() => {
    setRetrieveAppointments(false);
    AppointmentApi.getAllAppointment(radioValue, dateRange)
      .then((appointments) => {
        setRetrieveAppointments(true);
        setAppointments(appointments);
      });
  }, [radioValue, dateRange]);

  return (
    <main>
      <TopicTitle
        topicName='Appointments Remind & History'
        topicImage={appointmentsImage}
      />
      <p style={{marginBottom: 0}}>Display Order</p>
      <Row style={{display: 'flex', alignItems: 'center', height: '100%'}}>
        <Col xs={9}>
          <ButtonGroup style={{width: '100%'}}>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={radio.id}
                id={`radio-${idx}`}
                type='radio'
                variant='outline-primary'
                name='radio'
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
        <Col xs={3}>
          <Link to='./add'>
            <Button className='carnation-btn'>+ Add</Button>
          </Link>
        </Col>
      </Row>
      <p style={{margin: '1rem 0 0 0'}}>Filter by Date</p>
      <DateRangePicker onChange={handleChangeDateRange} value={dateRange} />
      {
        retrieveAppointments ?
          (
            <ul className='list-style-none'>
              {
                appointments.map((appointment) => (
                  <AppointmentCard
                    key={appointment.id}
                    id={appointment.id}
                    displayConfirmDeleteModal={
                      () => displayConfirmDeleteModal(appointment.id)
                    }
                    appointmentDate={appointment.appointment_date}
                    note={appointment.note}
                  />
                ))
              }
            </ul>
          ) :
          (
            <div style={{textAlign: 'center'}}>
              <Spinner animation='border' variant='dark' />
            </div>
          )
      }
      <ConfirmModal
        modalTitle='Delete Appointment'
        show={deleteConfirmShow}
        onHide={() => setDeleteConfirmShow(false)}
        btnText='Delete'
        clickBtn={() => deleteAppointment()}
      >
        <p style={{fontSize: '1.2rem'}}>Are you sure to delete?</p>
      </ConfirmModal>
    </main>
  );
}
