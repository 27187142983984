import * as React from 'react';
import {useParams} from 'react-router-dom';

export default function TestComponent() {
  const {id} = useParams();
  return (
    <main style={{padding: '1rem 0'}}>
      <h2>{id}</h2>
    </main>
  );
}
