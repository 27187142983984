import * as React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Image, Row, Col} from 'react-bootstrap';

export default function TopicCard({
  topicKey, topicName, topicImage, topicLocation, topicBgColor,
}) {
  const verifyUrlLink = (url) => /^https:\/\//.test(url);

  return (
    <>
      {
        verifyUrlLink(topicLocation) ?
          (
            <a href={topicLocation}>
              <li
                key={topicKey}
                className='topic-card'
                style={{backgroundColor: topicBgColor}}
              >
                <Row>
                  <Col
                    xs={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <h4>{topicName}</h4>
                  </Col>
                  <Col
                    xs={4}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0',
                    }}
                  >
                    <Image src={topicImage} />
                  </Col>
                </Row>
              </li>
            </a>
          ) :
          (
            <Link to={topicLocation}>
              <li
                key={topicKey}
                className='topic-card'
                style={{backgroundColor: topicBgColor}}
              >
                <Row>
                  <Col
                    xs={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <h4>{topicName}</h4>
                  </Col>
                  <Col
                    xs={4}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0',
                    }}
                  >
                    <Image src={topicImage} />
                  </Col>
                </Row>
              </li>
            </Link>
          )
      }
    </>
  );
}

TopicCard.propTypes = {
  topicKey: PropTypes.string.isRequired,
  topicName: PropTypes.string.isRequired,
  topicImage: PropTypes.string.isRequired,
  topicLocation: PropTypes.string.isRequired,
  topicBgColor: PropTypes.string.isRequired,
};
