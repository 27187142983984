import React, {useState, useEffect} from 'react';
import {
  Row, Col, Button, ButtonGroup, ToggleButton, Spinner,
} from 'react-bootstrap';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import TopicTitle from '../components/TopicTitle';
import OralPhotoCard from '../components/OralPhotoCard';
import ConfirmModal from '../components/ConfirmModal';
import oralPhotoImage from '../assets/image/icon-photos.png';

import * as OralPhotoApi from '../api/OralPhotoApi';

export default function OralPhotos() {
  const radios = [
    {id: 0, name: 'All', value: 'all'},
    {id: 1, name: 'Photos', value: 'Photo'},
    {id: 2, name: 'X-rays', value: 'X-ray'},
  ];
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [dateRange, setDateRange] = useState(
    [
      dayjs().subtract('1', 'month').format('YYYY-MM-DD'),
      dayjs().add('1', 'month').format('YYYY-MM-DD'),
    ],
  );
  const [radioValue, setRadioValue] = useState('all');
  const [openedOralPhoto, setOpenedOralPhoto] = useState();
  const [oralPhotos, setOralPhotos] = useState([]);
  const [retrieveOralPhotos, setRetrieveOralPhotos] = useState(false);

  const handleChangeDateRange = (date) => {
    setDateRange([
      dayjs(date[0]).format('YYYY-MM-DD'),
      dayjs(date[1]).format('YYYY-MM-DD'),
    ]);
  };

  const deleteOralPhoto = () => {
    OralPhotoApi.deleteOralPhoto(openedOralPhoto).then(() => {
      setOralPhotos(
        (preOralPhotos) => preOralPhotos.filter(
          (oralPhoto) => oralPhoto.id !== openedOralPhoto,
        ),
      );
      setConfirmModalShow(false);
      return true;
    });
  };

  useEffect(() => {
    setRetrieveOralPhotos(false);
    OralPhotoApi.getAllOralPhotos(radioValue, dateRange)
      .then((oralPhotos) => {
        setRetrieveOralPhotos(true);
        setOralPhotos(oralPhotos);
      });
  }, [radioValue, dateRange]);

  return (
    <main>
      <TopicTitle
        topicName='X-rays and Photos Storage'
        topicImage={oralPhotoImage}
      />
      <p style={{marginBottom: 0}}>Photo type</p>
      <Row style={{display: 'flex', alignItems: 'center', height: '100%'}}>
        <Col xs={9}>
          <ButtonGroup style={{width: '100%', backgroudColor: 'white'}}>
            {
              radios.map((radio) => (
                <ToggleButton
                  key={radio.id}
                  id={`radio-${radio.id}`}
                  type='radio'
                  variant='outline-primary'
                  name='radio'
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => setRadioValue(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))
            }
          </ButtonGroup>
        </Col>
        <Col xs={3}>
          <Link to='./add'>
            <Button className='carnation-btn'>+ Add</Button>
          </Link>
        </Col>
      </Row>
      <p style={{margin: '1rem 0 0 0'}}>Filter by Date</p>
      <DateRangePicker onChange={handleChangeDateRange} value={dateRange} />
      {
        retrieveOralPhotos ?
          (
            <ul className='list-style-none'>
              {
                oralPhotos.map((oralPhoto) => (
                  <OralPhotoCard
                    key={oralPhoto.id}
                    openedOralPhoto={openedOralPhoto}
                    setOpenedOralPhoto={setOpenedOralPhoto}
                    displayConfirmDeleteModal={() => setConfirmModalShow(true)}
                    oralPhoto={oralPhoto}
                  />
                ))
              }
            </ul>
          ) :
          (
            <div style={{textAlign: 'center'}}>
              <Spinner animation='border' variant='dark' />
            </div>
          )
      }
      <ConfirmModal
        modalTitle='Delete Photo'
        btnText='Delete'
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        clickBtn={() => deleteOralPhoto()}
      >
        <p style={{fontSize: '1.2rem'}}>Are you sure to delete?</p>
      </ConfirmModal>
    </main>
  );
}
