import React from 'react';

export default function DentalBookPrivacyPolicy() {
  return (
    <>
      <div>
        <h2>Web And Mobile Privacy Policy</h2>
        <section>
          <h4>Choice of law</h4>
          <p>Last updated on January 1, 2023</p>
          <p>
            Introduction Agape MedTecs Inc. on its own behalf
            and its affiliates and/or wholly owned
            subsidiaries (collectively referred to as
            &quot;Dental Book&quot;, &quot;we&quot;,
            &quot;us&quot;, or &quot;our&quot;), makes
            certain dental heath related information available
            to you and/or facilitates your purchase of certain
            dental hygiene products and access to telemedicine
            and medical record management services (&quot;Services&quot;).
          </p>
          <p>
            This Privacy Policy applies to visitors of the
            following publicly available websites:
            www.idental book.com and www.aidentalbook.com,
            and the publicly available associated domains and
            mobile applications owned and operated by Dental Book
            (collectively, the &quot;Websites&quot;).
            For the purposes of this Disclaimer,
            &quot;you&quot; and &quot;your&quot;
            means you as the visitor of the Websites.
          </p>
          <p>
            Scope of this Web and Mobile Privacy Policy
          </p>
          <p>
            This Privacy Policy describes the types of
            Personal Information we collect from visitors
            of our Websites and our practices for using,
            maintaining, sharing, and protecting it.
            It also describes the rights and choices you
            may have with respect to your Personal
            Information and how you may contact us.
          </p>
          <p>
            All information collected and stored by Dental Book
            or added/uploaded by you is considered Protected
            Health Information (&quot;PHI&quot;) and/or medical
            information and is governed by laws that apply to
            that information, for example the Health Insurance
            Portability and Accountability Act (HIPAA).
            How Dental Book uses and discloses such PHI is
            in accordance with the applicable Notice of
            Privacy Practices.  Dental Book will not use
            or disclose information received from you for
            advertising, marketing, or other use-based data
            mining purposes and will not combine it with
            other data and will not sell it.
          </p>
          <p>
            This Privacy Policy also does not apply to the
            collection and use of certain employment-related information.
          </p>
          <p>
            When using our Websites, you may choose to interact
            with features from third parties that operate
            independently from Dental Book, such as social
            media widgets and links to third-party websites.
            Dental Book has no control over and is not responsible
            for the privacy practices of such third parties.
            This Privacy Policy does not apply to the extent Dental
            Book does not own or control any linked websites or
            features you visit or use. We recommend that you
            familiarize yourself with the privacy practices
            of those third parties.
          </p>
          <p>
            California residents should read the information
            available in Section 12 below about the categories
            of personal information to be collected from them
            and the purposes for which the personal information
            will be used.
          </p>
          <p>
            This Privacy Policy is not a contract and does
            not create any contractual rights or obligations.
          </p>
        </section>

        <section>
          <h4>1. Types Of Personal Information We Collect</h4>
          <p>
            While using our Websites, you may provide us with
            certain information about you. We or our advertising
            partners also may automatically collect information
            through the use of cookies and other tracking
            technologies (see below).
          </p>
          <p>
            Personal Information that we collect through
            your use of the Websites may include IP Address,
            Device ID, and online identifier. We may use that
            Personal Information and link it to Internet or
            other electronic network activity information,
            and we may draw inferences about you from the
            information we collect. We may also collect
            your name, title, contact information, phone number,
            date of birth, state, country, zip code,
            your health plan, email address or login identification
            information only if you provide such information
            directly to us when you begin registration to be a
            customer of the Services or complete a web form
            seeking more information.
          </p>
        </section>

        <section>
          <h4>2. How We Collect Personal Information</h4>
          <p>
            We may collect Personal Information using the following methods:
          </p>
          <ul>
            <li>
              Directly from you when you provide it to us
              (such as information you enter into web forms, inquiries,
              responses, activity on the Websites, and during
              registration to be our customer).
            </li>
            <li>
              From third parties, such as analytics and
              email marketing service providers.
            </li>
            <li>
              Automatically through tracking technologies
              such as cookies, web beacons (also known as pixels)
              and log files, including over time and across
              third-party websites or other online services.
            </li>
          </ul>
          <p>
            &quot;Cookies&quot; are small files that a website
            stores on a user&apos;s computer or device.
            The Websites may use cookies for various purposes,
            including to keep the information you enter on
            multiple pages together.  Some of the cookies
            we use are &quot;session&quot; cookies, meaning that
            they are automatically deleted from your hard drive
            after you close your browser at the end of your session.
            Session cookies are used to optimize performance of the
            Websites and to limit the amount of redundant data that
            is downloaded during a single session.  We also may use
            &quot;persistent&quot; cookies, which remain on your
            computer or device unless deleted by you
            (or by your browser settings). We may use persistent
            cookies for various purposes, such as statistical
            analysis of performance to ensure the ongoing quality
            of our services. We and third parties may use session
            and persistent cookies for analytics and advertising
            purposes, as described herein. Most web browsers
            automatically accept cookies, but you may set your
            browser to block certain cookies (see below).
            In accordance with applicable law, we may obtain your
            consent separately before collecting information
            by automated means using cookies or similar devices.
          </p>
          <p>
            Our Websites may use Google Analytics, a vendor&apos;s
            service that uses cookies, web beacons, web pixels and/or
            similar technology to collect and store anonymous information
            about you. You can learn more about Google Analytics&apos;
            privacy policy and ways to opt out from Google Analytics
            tracking by visiting Google Analytics&apos; website.
          </p>
          <p>
            Our Websites may use Adobe&apos;s analytics and
            on-site personalization services, which use cookies,
            web beacons, web pixels and/or similar technology to
            collect and store information about you or your
            device or browser.  You can learn more about how
            Adobe may handle information collected through our
            use of its services, and your options for controlling
            this activity, by visiting Adobe&apos;s website.
          </p>
          <p>
            For more information about how we use Personal
            Information collected through tracking technologies
            and the ways you may be able to manage it, see below.
          </p>
        </section>

        <section>
          <h4>3. How We Use Your Personal Information</h4>
          <p>
            We may use your Personal Information for the following purposes:
          </p>
          <ul>
            <li>Provide the Services to you.</li>
            <li>
              Operate, maintain, supervise, administer,
              and enhance the Websites, including monitoring
              and analyzing the effectiveness of content
              on the Websites, aggregate site usage data,
              and other usage of the Websites such as
              checking your eligibility and assisting
              you in completing the registration process.
            </li>
            <li>
              Provide you with a tailored and user-friendly
              experience as you navigate our Websites.
            </li>
            <li>Promote and market our Websites to you.</li>
            <li>
              To complete the activity you specifically
              asked for, e.g., register to be a customer,
              obtain more information, or request a demo.
            </li>
            <li>
              Conduct research on users&apos; demographics,
              interests, and behavior based upon information
              provided during use of our Websites.
            </li>
            <li>
              Anonymize and aggregate information for analytics and reporting.
            </li>
            <li>
              Respond to law enforcement requests and court orders and
              legal process and carry out our
              legal and contractual obligations and enforce our rights.
            </li>
            <li>
              Authenticate use, detect potential fraudulent use,
              and otherwise maintain the security of the
              Websites and safety of users.
            </li>
            <li>
              Develop, test, improve, and demonstrate the Websites.
            </li>
            <li>Any other purpose with your consent.</li>
          </ul>
        </section>

        <section>
          <h4>4. How We Share Your Personal Information</h4>
          <p>
            We may share Personal Information with third
            parties in certain circumstances or for certain purposes, including:
          </p>
          <ul>
            <li>
              For business purposes. We may share your Personal
              Information with vendors and service providers,
              including our product and service providers,
              data hosting and data storage partners,
              analytics and advertising providers, technology
              services and support, and data security.
              We also may share Personal Information with
              professional advisors, such as auditors, law firms,
              and accounting firms. We may disclose your name,
              email address, date of birth, phone number,
              and address if you provided it to us via a form on the website.
            </li>
            <li>
              With your direction or consent.
              We may share your Personal Information
              with third parties if you request or direct
              us to do so. This includes your use of
              social media widgets on our Websites.
            </li>
            <li>
              With affiliates within our corporate group.
              We may share your Personal Information with
              any subsidiaries within our corporate group.
            </li>
            <li>
              Compliance with law. We may share your
              Personal Information to comply with applicable
              law or any obligations thereunder,
              including cooperation with law enforcement,
              judicial orders, and regulatory inquiries.
            </li>
            <li>
              In the context of a transaction.
              We may share your Personal Information
              connection with an asset sale, merger,
              bankruptcy, or other business transaction.
            </li>
            <li>
              For other business reasons. We may share your Personal
              Information to enforce any applicable legal disclaimer,
              and to ensure the safety and security of the
              Websites and/or our users.
            </li>
            <li>
              For advertising. Using cookies and web beacons,
              we may disclose Personal Information regarding your activity
              on our Websites to our advertising partners to optimize marketing.
              The Personal Information we share for this purpose is
              limited to email address, IP address, device ID, and online
              identifier. We do not share other identifying information such as
              names or any health information.
            </li>
          </ul>
          <p>
            We also may disclose deidentified information.
            Note that if you make any Personal Information
            publicly available on the Websites,
            anyone may see and use such information.
          </p>
        </section>

        <section>
          <h4>5. Social Features</h4>
          <p>
            Certain features of the Websites permit you to
            initiate interactions between the Websites and
            third-party services or platforms, such as
            social networks (&quot;Social Features&quot;).
            Social Features include features that allow you
            to click and access Dental Book&apos;s pages on
            certain third-party platforms, such as Facebook,
            Instagram, Twitter and LinkedIn as applicable,
            and from there to &quot;like&quot; or &quot;share&quot;
            our content on those platforms. Use of Social Features
            may entail a third party&apos;s collection and/or
            use of your data. If you use Social Features or similar
            third-party services, information you post or otherwise
            make accessible may be publicly displayed by the
            third-party service you are using. Both Dental Book
            and the third party may have access to information
            about you and your use of both the Websites and
            the third-party service.
          </p>
        </section>

        <section>
          <h4>6. Third Party Websites And Links</h4>
          <p>
            Our Websites may contain links to other online platforms
            operated by third parties. We do not control such other
            online platforms and are not responsible for their content,
            their privacy policies, or their use of your information.
            Information you provide on public or semi-public venues,
            including information you share on third-party social
            networking platforms
            (such as Facebook, Instagram, LinkedIn or Twitter)
            may also be viewable by other users of the Websites
            and/or users of those third-party online platforms
            without limitation as to its use by us or by a third
            party. Our inclusion of such links does not, by itself,
            imply any endorsement of the content on such platforms
            or of their owners or operators except as disclosed on
            the Websites. We expressly disclaim any and all liability
            for the actions of third parties, including but without
            limitation to actions relating to the use and/or
            disclosure of Personal Information by third parties.
            Any information submitted by you directly to these
            third parties is subject to that third
            party&apos;s privacy policy.
          </p>
        </section>

        <section>
          <h4>7. Cookies And Information Used For Advertising</h4>
          <p>
            As discussed above, on our Websites,
            we may collect and disclose Personal Information
            about your online activities for use in providing
            you with advertising about products and services
            tailored to your individual interests.
            This section of our Privacy Policy provides
            details and explains how to exercise your choices.
            You may see certain ads on other websites because
            we participate in advertising networks.
            Ad networks allow us to target our messaging to users
            through demographic, interest-based and contextual means.
            These networks track your online activities over time
            by collecting information through automated means,
            including through the use of cookies, web server logs,
            and web beacons (also known as pixels).
            The networks use this information to show you advertisements
            that may be tailored to your individual interests.
            The information our ad networks may collect includes
            information about your visits to websites that participate
            in the relevant advertising networks, such as the pages
            or advertisements you view and the actions
            you take on the websites.
            This data collection takes place both on our Websites and
            on third-party websites that participate in the ad networks.
            This process also helps us track the effectiveness
            of our marketing efforts.
          </p>
        </section>

        <section>
          <h4>8. Children&apos;s Privacy</h4>
          <p>
            Our Websites are not intended for children under
            16 years of age, and we do not knowingly collect or
            sell Personal Information from children under 16.
            If you are under 16, do not use or provide any
            information on these Websites or through any of
            its features. If we learn we have collected or
            received Personal Information from a child under
            16 without verification of parental consent,
            we will delete it. If you are the parent or guardian
            of a child under 16 years of age whom you believe
            might have provided us with their Personal Information,
            you may contact us using the below information
            to request that it be deleted.
          </p>
        </section>

        <section>
          <h4>9. Security Of Personal Information</h4>
          <p>
            We take steps to secure Personal Information through administrative,
            technical, and physical safeguards designed to protect against
            the risk of accidental, unlawful, or unauthorized destruction,
            loss, alteration, access, disclosure, or use. Unfortunately,
            we cannot guarantee the security of information transmitted
            through the Internet, and where we have given you
            (or where you have chosen) a password, you are
            responsible for keeping this password confidential.
          </p>
        </section>

        <section>
          <h4>10. Retention Of Personal Information</h4>
          <p>
            We generally retain records only as long as necessary
            and as required for our business operations,
            for archival purposes, and/or to satisfy legal requirements.
            When determining the appropriate retention period for
            Personal Information, we take into account various criteria,
            such as the amount, nature, and sensitivity of the Personal
            Information; potential risk of harm from unauthorized use
            or disclosure; purposes for which we process your Personal
            Information; whether we can achieve those purposes through
            other means; and business operations and legal requirements.
            Because we maintain our Websites to protect from accidental
            or malicious loss and destruction, residual copies of your
            Personal Information may be retained in our backup and
            archival systems for a limited period of time, after which
            the information will be automatically deleted or put beyond
            use where deletion is not possible.
          </p>
        </section>

        <section>
          <h4>11. International Users</h4>
          <p>
            The Websites are controlled and operated by us from
            the United States and are not intended to subject
            us to the laws or jurisdiction of any state,
            country or territory other than that of the United States.
            Any information you provide to us through use of our Websites
            may be stored and processed, transferred between and accessed
            from the United States and other countries that may not
            guarantee the same level of protection of personal data
            as the one in which you reside. However, we will handle
            your Personal Information in accordance with this
            Privacy Policy regardless of where your Personal
            Information is stored/accessed.
          </p>
        </section>

        <section>
          <h4>12. Notice To California Residents</h4>
          <p>
            If you are a California resident, certain Personal
            Information that we collect about you is subject
            to the California Consumer Privacy Act (CCPA).
          </p>
          <p>
            Please note that the CCPA does not apply to, among other things,
          </p>
          <ul>
            <li>
              Information that is lawfully made available
              from federal, state, or local government records;
            </li>
            <li>Consumer information that is deidentified or aggregated;</li>
            <li>
              protected health information that is collected
              by a covered entity or business associate governed by HIPAA or
            </li>
            <li>
              Medical information maintained by a provider
              of health care governed the Confidentiality
              of Medical Information Act (CMIA)
            </li>
          </ul>
          <p><b><i>
            Collection of Personal Information –
            Currently and in the Preceding 12 Months
          </i></b></p>
          <p>
            We collect Personal Information as defined by the
            CCPA, which is information that identifies, relates to,
            describes, is reasonably capable of being associated with,
            or could reasonably be linked, directly or indirectly,
            with a particular consumer or household. Personal
            Information does not include de-identified or aggregate
            information; publicly available information that is
            lawfully made available from federal, state, or local
            government records; and information covered by
            certain sector-specific privacy laws.
          </p>
          <p>
            Dental Book currently collects and has collected
            the following categories of Personal Information
            about consumers:
          </p>
          <ul>
            <li>
              Identifiers, Internet Protocol (IP)
              address, online identifier, device identifier.
            </li>
          </ul>
          <p>
            We may collect your name, email address,
            medical insurance information, date of birth,
            phone number, and address only if you provided
            it to us via a form on the website or during
            an incomplete or failed registration.
          </p>
          <ul>
            <li>
              Commercial information, business contact information
              if you have provided it to us via a form
              (such to register for services, obtain more information,
              request a demo, request an RFP) on the website,
              including name, company name, physical address,
              email address, telephone, and fax number;
              information on actions taken on our Websites,
              which may include information about Websites
              considered and information about preferences
              and behavior that we collect on our Websites
              or purchase from third parties in order to
              target consumers for digital advertisements
              or to personalize content we deliver on our Websites.
            </li>
            <li>
              Internet or other electronic network activity information,
              including, but not limited to, browsing history, search history,
              and information regarding a consumer&apos;s interaction with
              the Websites, applications, or advertisements.
            </li>
          </ul>
          <p>
            Dental Book collects Personal Information
            from the following categories of sources:
          </p>
          <ul>
            <li>
              Directly and indirectly from you, including
              through your interaction with the Websites
            </li>
            <li>Social media platforms</li>
            <li>
              Third party partners such as
              analytics or marketing providers
            </li>
            <li>Automatically through tracking technologies.</li>
          </ul>
          <p>See Section 2 for additional detail.</p>
          <p>
            We may use your Personal Information for the following purposes:
          </p>
          <ul>
            <li>
              Operate, maintain, supervise, administer, and enhance
              the Websites, including monitoring and analyzing
              the effectiveness of content on the Websites,
              aggregate site usage data, and other usage of
              the Websites such as checking your eligibility
              and assisting you in completing the registration process.
            </li>
            <li>
              Provide you with a tailored and user-friendly
              experience as you navigate our Websites.
            </li>
            <li>
              Promote and market our Websites to you.</li>
            <li>
              To complete the activity you specifically asked for, e.g.,
              register as a customer, obtain more information,
              request a demo, or request an RFP.
            </li>
            <li>
              Conduct research on users&apos; demographics,
              interests, and behavior based upon information
              provided during use of our Websites.
            </li>
            <li>
              Anonymize and aggregate information for analytics and reporting.
            </li>
            <li>
              Respond to law enforcement requests and court orders
              and legal process and carry out our legal and
              contractual obligations and enforce our rights.
            </li>
            <li>
              Authenticate use, detect potential fraudulent use, and
              otherwise maintain the security of the Websites and
              safety of users.
            </li>
            <li>
              Develop, test, improve, and demonstrate the Websites.
            </li>
            <li>Any other purpose with your consent.</li>
          </ul>
          <p>
            If you are a California resident,
            and in accordance with the CCPA, you have the right to:
          </p>
          <ul>
            <li>
              Request to know and access the Personal
              Information we collect, use, and disclose;
            </li>
            <li>
              Request deletion of Personal Information;
            </li>
            <li>
              Opt-out of the sale of Personal Information; and
            </li>
            <li>
              Not receive discriminatory treatment
              by Dental Book for exercising these rights.
            </li>
          </ul>
          <p><b><i>Requests to Know and Access</i></b></p>
          <p>
            You have the right to request that we
            disclose to you the following information:
          </p>
          <ul>
            <li>
              Categories of Personal Information that
              Dental Book has collected about you, disclosed
              about you for a business purpose, or sold;
            </li>
            <li>
              Categories of sources from which the
              Personal Information is collected;
            </li>
            <li>
              Categories of third parties with whom
              Dental Book shares or to whom Dental Book
              sells Personal Information;
            </li>
            <li>
              The business or commercial purpose for
              collecting and selling Personal Information; and
            </li>
            <li>
              Specific pieces of Personal Information
              that Dental Book has collected about you.
            </li>
          </ul>
          <p>
            Instructions for submitting a verifiable request are provided below.
          </p>
          <p>
            You may only make a request for access
            twice within a 12-month period.
          </p>
          <p><b><i>Requests to Delete</i></b></p>
          <p>
            You have the right to request that we delete Personal
            Information about you that we have collected,
            subject to certain exceptions.
          </p>
          <p>
            Instructions for submitting a verifiable request are provided below.
          </p>
          <p><b><i>Requests to not sell your Personal Information</i></b></p>
          <p>Dental Book does not sell your Personal Information.</p>
          <p><b><i>How to Exercise Your Rights</i></b></p>
          <p>
            To make Requests to Know or Access and Requests
            to Delete, please submit a written request to:
          </p>
          <p>
            <b>
              Privacy Officer<br />
              Agape MedTecs Inc.<br />
              10440 S. De Anza Blvd Suite D4, Cupertino, CA 95014<br />
              Or by sending an email to:
              <a
                href='mailto:privacy@aidentalbook.com'
                target='_blank'
                rel='noreferrer'
              >
                privacy@aidentalbook.com
              </a>
            </b>
          </p>
          <p>
            As required under applicable law, we must take steps to
            verify your request before we can provide Personal
            Information to you, delete Personal Information, or
            otherwise process your request. To verify your request,
            we may require you to provide your name, physical address,
            email address, contact information, and information about
            your account or previous transactions with us. If you have
            only visited our Websites and did not provide any Personal
            Information to us via a web form or through the registration
            \process, we will need you to provide us with your IP address
            or device ID in order for us to determine if we have that
            information. We will not be able to determine if we have your
            IP address or device ID from your name, physical address,
            email address, contact information, or account information.
          </p>
          <p>
            We will further verify and respond to your request
            consistent with applicable law, taking into account
            the type and sensitivity of the Personal Information
            subject to the request. We may need to request additional
            Personal Information from you, such as your date of birth
            or government identifier, in order to protect against
            fraudulent requests.
          </p>
          <p>
            We will deliver Personal Information that we are required by
            law to disclose to you in the manner required by law within
            45 days after receipt of a verifiable request, unless
            we notify you that we require additional time to respond,
            in which case we will respond within such additional period
            of time required by law. We may deliver the Personal
            Information to you electronically or by mail at your option.
            If electronically, then we will deliver the information in a
            portable and, to the extent technically feasible,
            in a readily useable format that allows you to transmit
            the information from one entity to another without hindrance.
          </p>
          <p>
            Only you, or someone legally authorized to act on your behalf,
            may make a verifiable consumer request related to your Personal
            Information. You may designate an authorized agent to request
            any of the above rights on your behalf. You may make such a
            designation by providing the agent with written permission,
            signed by you, to act on your behalf. Your agent may contact
            us by the information provided in the &quot;
            How to Contact Us&quot; section below to make a request on your
            behalf. Even if you choose to use an agent, we may, as permitted
            by law, require verification of the agent&apos;s
            authorization to act on your behalf, require you to confirm you
            have authorized the agent to act on your behalf, or require you
            to verify your own identity.
          </p>
          <p><b><i>Right to Nondiscrimination</i></b></p>
          <p>
            You have the right to be free from discriminatory treatment
            for exercising the privacy rights conferred by the CCPA,
            including not being: denied goods or services; charged
            different prices or rates for goods or services,
            including through the use of discounts or other benefits
            or imposing penalties; provided a different level or
            quality of goods or services; or suggested that you will
            receive a different price or rate for goods or services
            or a different level or quality of goods or services.
          </p>
        </section>

        <section>
          <h4>13. Updating This Privacy Policy</h4>
          <p>
            This Privacy Policy may be updated periodically to
            reflect changes in our privacy practices. It is your
            responsibility to review the Privacy Policy from
            time to time to view any such changes.
          </p>
        </section>

        <section>
          <h4>14. How To Contact Us</h4>
          <p>
            Should you have any questions about our privacy practices or
            this Privacy Policy, please email us
            at privacy@aidentalbook.com or contact us at:
          </p>
          <ul style={{listStyleType: 'none'}}>
            <li>Privacy Officer</li>
            <li>Agape MedTecs Inc.</li>
            <li>10440 S. De Anza Blvd Suite D4, Cupertino, CA 95014</li>
          </ul>
        </section>
      </div>
    </>
  );
}
