import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from '@greatsumini/react-facebook-login';
import {useAuth} from '../hooks/AuthContext';
import {useNavigate} from 'react-router-dom';
import {Button, Image} from 'react-bootstrap';
import icon from '../assets/image/icon-facebook.png';

export default function FacebookLoginBtn(props) {
  const auth = useAuth();
  const navigate = useNavigate();

  const responseFacebook = (facebookUser) => {
    const data = {
      login_type: 'facebook',
      token: facebookUser.accessToken,
    };
    auth.login(data, () => {
      navigate(props.from, {replace: true});
    });
  };

  return (
    <>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        onSuccess={(response) => {
          responseFacebook(response);
        }}
        onFail={(error) => {
          console.log('Login Failed!', error);
        }}
        onProfileSuccess={() => {
        }}
        dialogParams={{
          response_type: 'token',
        }}
        render={({onClick}) => (
          <Button
            onClick={onClick}
            className='login-btn'
            variant='outline-light'
            disabled={!props.disabled}
          >
            <Image src={icon} className='login-icon' />
            <span style={{verticalAlign: 'bottom'}}>Sign in with Facebook</span>
          </Button>
        )}
      />
    </>
  );
}

FacebookLoginBtn.propTypes = {
  from: PropTypes.string,
  disabled: PropTypes.bool,
};
